import React, { Component, createContext } from 'react';
import { disciplineAPI }                   from '../../apis/metadata';

export const DisciplineContext = createContext ();

class disciplineContextProvider extends Component {
		state = {
				disciplineData : [],
		}

		componentDidMount = async () => {
				let data  = await disciplineAPI ();

				for (let i = 0; i < data.length; i++)
						data[i].isChecked = false;

				this.setState ({ disciplineData : data });
		}

		reset = () => {
				let data = [...this.state.disciplineData];

				if (!this.resetDiff ())
						return;

				for (let i = 0; i < data.length; i++)
						if (data[i].isChecked)
								data[i].isChecked = false;

				this.setState ({ disciplineData : data });
		}

		resetDiff = () => {
				let data = [...this.state.disciplineData];

				for (let i = 0; i < data.length; i++)
						if (data[i].isChecked)
								return true;
				return false;
		}

		toggleCheckbox = (_id) => {
				let data = [...this.state.disciplineData];

				for (let i=0;i<data.length;i++) {
						if (data[i].id === _id) {
								data[i].isChecked = !data[i].isChecked;
								break;
						}
				}
				//console.log ("setting isChecked id= ", _id);
				this.setState ({ disciplineData : data });
		}

		render () {
				return (
						<DisciplineContext.Provider value={{ ...this.state, toggleCheckbox: this.toggleCheckbox, resetDis: this.reset }}>
							{this.props.children}
						</DisciplineContext.Provider>
				);
		}
}

export default disciplineContextProvider;
