import React, { memo }                           from 'react';
import { withStyles }                  from '@material-ui/core/styles';
import Typography                      from '@material-ui/core/Typography';

import { NOTFOUND }                    from '../../../utils/message';
import GrantListing                    from './grantListing';

const styles = (theme) => ({
		root: {
				flexGrow : 1,
		},
});

function FullyFundedSection ({ data }) {
		const presentContent = () => {
				if (!data.p || !data.p.length)
						return (<p>{NOTFOUND}</p>);

				return <GrantListing data={data.p} />;
		}

		const futureContent = () => {
				let presentIds  = [];
				let futureGrant = [];

				if (data.p && data.p.length)
						presentIds = data.p.map ((item) => item.id);

				if (data.f && data.f.length)
						futureGrant = data.f.filter ((ele) => !presentIds.includes (ele.id));

				if (!futureGrant.length)
						return ( <p>{NOTFOUND}</p>);

				return <GrantListing data={futureGrant} />;
		}

		return (
				<>
					<Typography variant="h6" gutterBottom>
						<b>On-going Fully Funded Scholarship</b>
					</Typography>
					{presentContent ()}
					<br />
					<Typography variant="h6" gutterBottom>
						<b>Upcoming Fully Funded Scholarship</b>
					</Typography>
					{futureContent ()}
				</>
		);
}

export default withStyles (styles)(memo (FullyFundedSection));
