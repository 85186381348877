import React          from 'react';
import { makeStyles } from '@material-ui/core/styles';
import QRCode         from "react-qr-code";

const useStyles = makeStyles({
		imgCenter: {
				justifyContent: 'flex-end',
				alignItems    : 'center',
				display       : 'flex',
		},
});

export default function RightQrCode ({ data }) {
		const classes = useStyles ();

		//FIXME : Try to change this constant value of 800
		//        into something meaningful
		//TODO  : Insert the logo of the website
		//        https://www.npmjs.com/package/react-qrcode-logo
		const qrSize = () => {
				const windowWidth = window.innerWidth;

				if (windowWidth > 800)
						return 128;

				return 64;
		}

		return (
				<div className={classes.imgCenter}>
						<QRCode
								size ={qrSize ()}
								title={'overseas.one'}
								value={window.location.href}
						/>
				</div>
		);
};
