import Moment from 'moment';

export function timeLeveler (t) {
		let today = Moment.utc ();

		let years = today.diff (t, 'years');
		if (years)
				return years + ' years ago';

		let months = today.diff (t, 'months');
		if (months)
				return months + ' months ago';

		let days = today.diff (t, 'days');
		if (days)
				return days + ' days ago';

		return 'Just now';
}
