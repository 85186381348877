import React, { useContext, useState } from 'react';
import { makeStyles }                  from '@material-ui/core/styles';
import TextField                       from '@material-ui/core/TextField';
import Rating                          from '@material-ui/lab/Rating';
import Button                          from '@material-ui/core/Button';
import Grid                            from '@material-ui/core/Grid';
import queryString                     from "query-string";

import { UserContext }                 from '../../../contexts/user';
import { saveCommentAPI }              from '../../../apis/user';
import { REMARK_ADD }                  from '../../../utils/message';
import { commentMax }                  from '../../../config';
import { validate }                    from '../../../utils/validate/comment';
import Messenger                       from '../../../components/messenger';
import AvatarName                      from '../atoms/avatarName';

const useStyles = makeStyles((theme) => ({
		root: {
				width          : '100%',
				margin         : '20px',
		},
		btn: {
				marginTop      : '10px',
				textTransform  : 'none',
				float          : 'right',
		},
		purple: {
				color          : "white",
				backgroundColor: theme.palette.primary.main,
				width          : theme.spacing (4),
				height         : theme.spacing (4),
		}
}));

const initialState = {
		remark : '',
		rate   : 0,
		loading: false,
		msg    : '',
};

export default function CommentForm () {
		const classes                      = useStyles ();
		var { user }                       = useContext (UserContext);
		const [{ remark, rate, loading,
			msg}, setState]                = useState (initialState);

		const handleComment = async () => {
				try {
						setState (prevState => ({ ...prevState, ['loading']: true }));

						let d = {};

						d['c_id']       = queryString.parse (window.location.search).sid;
						d['u_id']       = user.id;
						d['rate']       = rate;
						d['remark']     = remark;
						d['first_name'] = user.first_name;
						d['last_name']  = user.last_name;

						validate (d);
						let res = await saveCommentAPI (d, user.token);
						setState (prevState => ({ ...initialState, 'msg' : REMARK_ADD }));
						//console.log (res);
				}
				catch (err) {
						//console.log (err);
						setState (prevState => ({ ...initialState, 'msg' : err.message }));
				}
		}

		const handleMesenger = () => {
				//console.log ('=============');
				setState ({ ...initialState });
		};

		return (
				<div className={classes.root}>
					<AvatarName 
						firstName={user && user.first_name}
						lastName={user && user.last_name}
					/>
					<TextField
						disabled={!user ? true: false}
						style={{'width': '100%'}}
						placeholder="Your comments/opinions..."
						multiline
						onChange={(e) => setState (prevState => ({ ...prevState, ['remark']: e.target.value }))}
						variant="outlined"
						value={remark}
						inputProps={{ maxLength: commentMax }}
					/>
					<Grid container style={{justifyContent: 'flex-end'}}>
						<Rating
							disabled={!user ? true: false}
							name="rating-comments"
							size="small"
							precision={0.5}
							value={rate || 0}
							onChange={(e, v) => { setState (prevState => ({ ...prevState, ['rate']: v }))}}
						/>
					</Grid>
					<Button className={classes.btn} disabled={!user ? true: false} variant="contained" size="medium" color="primary" onClick={handleComment}>
						Submit
					</Button>
					<Messenger
						msg={msg}
						open={msg.length ? true: false}
						handleMesenger={handleMesenger}
					/>
				</div>
		);
}
