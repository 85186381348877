import React                       from 'react';
import { makeStyles }              from '@material-ui/core/styles';
import Paper                       from '@material-ui/core/Paper';
import Grid                        from '@material-ui/core/Grid';
import Button                      from '@material-ui/core/Button';
import TrackChangesIcon            from '@material-ui/icons/TrackChanges';
import Tooltip                     from '@material-ui/core/Tooltip';
import Book                        from '@material-ui/icons/Book';
import { Link }                    from 'react-router-dom';
import Moment                      from 'moment';

import { springColor, summerColor,
		autumnColor }              from '../../../config';
import { semesterTrackUnit }       from '../../../utils/exchange';

const useStyles = makeStyles((theme) => ({
		iconCenter: {
				display       : 'flex',
				alignItems    : 'center',
				justifyContent: 'center',
		},
		iconSize: {
				fontSize      : '50px',
		},
		eachSubject: {
				padding       : theme.spacing (2),
				color         : theme.palette.text.secondary,
		},
}));

export default function Subject ({ data, degree }) {
		const classes = useStyles ();

		const onMoreClick = (sid) => {
				/*if (!url || !url.length)
					return;
				window.open(url, '_blank');*/

				return "course?sid=" + sid;
		}

		const degreeNames = (data, degreeList) => {
				if (!data || !data.degree_ids)
						return '';

				if (!degreeList.length)
						return '';

				let _degrees = degreeList.filter ((u) => {
						if (data.degree_ids.includes (u.id))
								return u;
				});
				
				return _degrees;
		}

		return (
				<Paper className={classes.eachSubject} style={{ borderLeft : '10px solid '+semesterTrackUnit (data.semester)['c']}}>
				<Grid container spacing={1}>
					<Grid item xs={2} className={classes.iconCenter}>
						<Book className={classes.iconSize}/>
					</Grid>
					<Grid item xs={8} container>
						<Grid item xs={12}>
							{data.title}
						</Grid>
						<Grid item xs={12}>
							{degreeNames (data, degree).map ((u) => { return u.name; }).join (', ')}
						</Grid>
						<Grid item xs={12}>
							{data.dept}
						</Grid>
						<Grid item xs={12}>
							{data.university}
						</Grid>
						<Grid item xs={12}>
							{semesterTrackUnit (data.semester)['n']} Semester (AY)
						</Grid>
						<Grid item xs={12}>
							{data.credit} Credits
						</Grid>
						<Button
							component={Link}
							to={onMoreClick (data._id)}
							target="_blank"
							variant="outlined"
							size='small'
							color="primary"
							style={{textTransform: 'none', margin: '10px 0 0 0'}}>
							Learn More
						</Button>
					</Grid>
					<Grid item xs={2} container style={{textAlign: 'right'}}>
						<Grid item xs={12}>
							<br />
							<Tooltip title="Track">
								<TrackChangesIcon />
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
				</Paper>
		);
}
