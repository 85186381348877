import React      from "react";
import { Button } from '@material-ui/core';
import { Link }   from "react-router-dom";

class LinkButton extends React.Component {
		render() {
				return (
						<Button
							component={Link}
							to={'/app/login'}
							color="primary"
							variant="outlined"
							style={{margin: '10px'}}>
								<b> Login </b>
						</Button>
				);
		}
}

export default LinkButton;
