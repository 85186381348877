import React             from "react";
import { withStyles }    from "@material-ui/core/styles";

const styles = theme => ({
		conatiner: {
				color    : '#718096',
				textAlign: 'justify',
		},
});

function Suffer (props) {
		const { classes } = props;

		return (
			<div className={classes.conatiner}>
				<h1>Why should I tell The Story ?</h1>
				<p>
					I searched almost half the globe to find scholarships either from universities
					or public institutions.
					Unfortunately, I couldn’t touch a single one.
					I made many mistakes at various places.
					For example, one of the US based company gave an offer to attend an interview and
					in return you get some free information.
					After attending the same, I got useless information.
					This actually strengthened my ideas to build this application.
					I managed and learned a lot in the admission period of almost 6 months.
					I found a lot of information, scholarships/colleges, search websites and countless.
					Overall, I spent a lot of time in hit-and-trial methods.
				</p>
				<p>
					Now, with all that knowledge I want to extend my hand to students with this application, worldwide.
					Similar mistakes shouldn’t be a fresher's fate anymore.
					The gap of checking - each university admission deadline, university courses, fully-funded scholarship
					by government and private entities, the availability of exact discipline and
					much more will now be available under a single umbrella.
					I couldn’t use this, as I was building while applying, so it’s your chance to use
					this information and build your career.
				</p>
				<p>
					Please do share your stories/journeys with us so that it helps the upcoming generation
					in getting ready for what the future holds for them.
				</p>
				<p>
					And remember, this search & map will always be at no-cost.
					<br />
					Wish you all the best and keep exploring heights!
				</p>
			</div>
		);
}

export default withStyles (styles)(Suffer);
