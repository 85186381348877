import React                  from "react";
import { withStyles }         from "@material-ui/core/styles";
import Grid                   from '@material-ui/core/Grid';
import Card                   from '@material-ui/core/Card';
import CardActions            from '@material-ui/core/CardActions';
import CardContent            from '@material-ui/core/CardContent';
import Button                 from '@material-ui/core/Button';
import ArrowBackIcon          from '@material-ui/icons/ArrowBack';
import Link                   from '@material-ui/core/Link';

import RegisterForm           from '../atoms/registerForm';
import { registerAPI }        from '../../../apis/user';
import Messenger              from '../../../components/messenger';
import { validate }           from '../../../utils/validate/registerUser';

const styles = theme => ({
		grid: {
				width         :'100%',
		},
		gridWidth: {
				width         :'100%',
				display       :'flex',
				justifyContent:'center',
				alignItems    :'center',
		},
});

const initialState = {
		firstName : '',
		lastName  : '',
		email     : '',
		passwd    : '',
		rePasswd  : '',
		phone     : '',
		checked   : false,
		visibility: false,
};

const initialStateMsg = {
		msg : '',
};

function Register (props) {
		const { classes, handleViewChange }             = props;
		const [{ firstName, lastName, email, passwd, rePasswd,
				phone, checked, visibility }, setState] = React.useState (initialState);
		const [{ msg }, setStateMsg]                    = React.useState (initialStateMsg);                   

		const handleField = (name, value) => {
				setState (prevState => ({ ...prevState, [name]: value }));
		};

		const handleMesenger = () => {
				//console.log ('=============');
				setStateMsg ({ ...initialStateMsg });
		};

		const handleSignUp = async () => {
				try {
						let data        = {};
						data['first_name'] = firstName;
						data['last_name']  = lastName;
						data['email']      = email;
						data['passwd']     = passwd;
						data['rePasswd']   = rePasswd;
						data['phone']      = phone;
						data['offer']      = checked;

						validate (data);

						let res            = await registerAPI (data);
						setStateMsg (prevState => ({ ...prevState, 'msg' : res.msg }));

						//console.log (res);
						setState ({ ...initialState });
				}
				catch (err) {
						setStateMsg (prevState => ({ ...prevState, 'msg' : err.message }));
				}
		};

		return (
					<Grid container spacing={2} className={classes.grid}>
						<Grid item xs={false} md={4}></Grid>
						<Grid container item xs={12} md={4} className={classes.gridWidth}>
							<Card style={{ width: '100%', padding: '20px', textAlign: 'center' }}>
								<CardContent>
									<ArrowBackIcon
										style={{display: 'flex', alignItems: 'flex-start'}}
										onClick={() => handleViewChange ('signin')}/>
									<RegisterForm
										firstName={firstName}
										lastName={lastName}
										email={email}
										passwd={passwd}
										visibility={visibility}
										rePasswd={rePasswd}
										phone={phone}
										checked={checked}
										fieldChange={handleField}/>
								</CardContent>
								<br />
								<CardActions>
									<Button size="medium" variant="contained" color="primary"
										style={{textTransform: 'none', width: '100%'}}
										onClick={handleSignUp}>
										Sign up
									</Button>
								</CardActions>
								<br />
								<hr
									style={{
										color          : 'grey',
										backgroundColor: 'grey',
										height         : 2
									}}/>
								<br />
								<Link
									onClick={() => handleViewChange ('signin')}
									component="button">
									Already have an Overseas.one account? Log in
								</Link>
							</Card>
						</Grid>
						<Messenger
							open={msg.length ? true: false}
							msg={msg}
							handleMesenger={handleMesenger}
						/>
					</Grid>
		);
}

export default withStyles (styles)(Register);
