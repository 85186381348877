import React                  from "react";
import { withStyles }         from "@material-ui/core/styles";
import TextField              from '@material-ui/core/TextField';
import FormControl            from '@material-ui/core/FormControl';
import InputLabel             from '@material-ui/core/InputLabel';
import Input                  from '@material-ui/core/Input';
import InputAdornment         from '@material-ui/core/InputAdornment';
import IconButton             from '@material-ui/core/IconButton';
import Visibility             from '@material-ui/icons/Visibility';
import VisibilityOff          from '@material-ui/icons/VisibilityOff';

const styles = theme => ({
});

function UserForm (props) {
		const { email, passwd, visibility, //classes
				onChangeEmail, onChangePasswd, onChangeVisibility,
				handleEnterKey } = props;

		return (
			<>
				<FormControl fullWidth  variant="standard">
					<TextField
						fullWidth
						id="email"
						label="Email"
						variant="standard"
						value={email}
						onChange={(e) => onChangeEmail (e.target.value)}
					/>
				</FormControl>
				<br />
				<br />

				<FormControl fullWidth  variant="standard">
					<InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
					<Input
						id="standard-adornment-password"
						type={visibility ? 'text' : 'password'}
						value={passwd}
						onChange={(e) => onChangePasswd (e.target.value)}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => onChangeVisibility ()}
								>
									{visibility ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						label="Password"
					/>
				</FormControl>
			</>
		);
}

export default withStyles (styles)(UserForm);
