import React, { useContext }  from 'react';
import { makeStyles }         from '@material-ui/core/styles';
import Paper                  from '@material-ui/core/Paper';
import Grid                   from '@material-ui/core/Grid';

import Subject                from './subject';
import { ExchangeContext }    from '../../../contexts/exchange';
import { DegreeContext }      from '../../../contexts/filters/degree';

const useStyles = makeStyles((theme) => ({
		root: {
				flexGrow: 1,
		},
		paper: {
				padding : theme.spacing(1),
				color   : theme.palette.text.secondary,
		},
		leftPane: {
				color   : theme.palette.text.secondary,
		},
		eachSubject: {
				padding : theme.spacing (2),
				color   : theme.palette.text.secondary,
		},
		noData: {
			textAlign   : 'center',
			width       : '100%',
		},
}));

export default function CenterPane () {
		const classes              = useStyles ();
		var { degreeData }         = useContext (DegreeContext);
		var { exchangeData, page } = useContext (ExchangeContext);

		if (!Object.keys (exchangeData).length)
				return (
						<div className={classes.noData}>
							<h2>No Data Found</h2>
						</div>
				);

		return (
				<>
					{
						exchangeData[page].map ((subject)=> (
							<Grid item xs={12} key={subject._id}>
									<Subject
										data={subject}
										degree={degreeData}
									/>
							</Grid>
						))
					}
				</>
		);
}
