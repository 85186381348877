import React             from "react";
import { withStyles }    from "@material-ui/core/styles";
import Grid              from '@material-ui/core/Grid';
import Typography        from '@material-ui/core/Typography';
import Avatar            from '@material-ui/core/Avatar';
import CheckIcon         from '@material-ui/icons/Check';

const styles = theme => ({
		container: {
				paddingTop     : '40px',
		},
		rightPart: {
				padding        : '10px',
		},
		highlighter: {
				color          : '#f9b934',
				fontWeight     : 'bold',
		},
		titleColor: {
				color          : '#2d3748',
		},
		subTitleColor: {
				color          : '#718096',
		},
		avatar: {
				width          : '20px',
				height         : '20px',
				backgroundColor: 'indigo',
		},
		spaceUp: {
				marginTop      : '20px',
		},
		spaceDown: {
				marginBottom   : '30px',
		},
});

function StoryContent (props) {
		const { classes } = props;
		const punchLine   = 'MAKE IT WORK DIFFERENTLY';
		const title       = ['A powerfull story for all', 'ever created', 'for community'];
		const body        = ['A forward journey to grow, study and cherish the life-changing experience ',
				'from a foreign university lead me to create this. ',
				'Sole aim is to help the community to visualise the world admissions & ',
				'scholarshsip in single go with - '
		];
		const bullet      = ['Single source of data', 'Consize overview of the world',
				'Help to and from community', 'Find | Search | Track GoAbroad'
		];

		return (
					<Grid container item md={6} xs={12} justify="center" direction="column" className={classes.rightPart}>
						<Grid container item>
							<Typography variant="subtitle2" color="primary">
								{punchLine}
							</Typography>
							<Grid container item md={12} xs={12} style={{marginTop: '50px'}}>
									<p></p>
							</Grid>
							<Typography variant="h4" style={{marginBottom: '30px'}}>
								<b>{title[0]}</b>
								<br />
								<span className={classes.highlighter}>{title[1]}</span>
								<br />
								<span className={classes.highlighter}>{title[2]}</span>
							</Typography>
							<br /><br />
							<Typography variant="body1" className={classes.subTitleColor}>
								{body.join ('')}
							</Typography>
							<br />
							<Grid container>
							{
								bullet.map ((pt, index) => (
									<Grid container item md={6} xs={12} className={classes.spaceUp} key={index}>
										<Grid item xs={1}>
											<Avatar className={classes.avatar}>
												<CheckIcon style={{fontSize: 15}}/>
											</Avatar>
										</Grid>
										<Grid item xs={11} className={classes.subTitleColor}>
											{pt}
										</Grid>
									</Grid>
								))
							}
							</Grid>
						</Grid>
					</Grid>
		);
}

export default withStyles (styles)(StoryContent);
