import React, { useState }     from "react";
import { withStyles }          from "@material-ui/core/styles";
import Grid                    from '@material-ui/core/Grid';
import Typography              from '@material-ui/core/Typography';
import TextField               from '@material-ui/core/TextField';
import Button                  from '@material-ui/core/Button';

import { enrollNewsletterAPI } from '../../../apis/newsletter';

const styles = theme => ({
	container: {
		width        : '100%',
		padding      : '60px 10px 60px 10px',
	},
	box: {
		textAlign    : 'center',
	},
	highlighter: {
		color        : '#f9b934',
		fontWeight   : 'bold',
	},
	textColor: {
		color        : '#718096',
	},
	fullCover: {
		height       : '100%',
		width        : '100%',
		textTransform: 'none',
		borderRadius : '0px 4px 4px 0',
		marginLeft   : '-4px',//to overlap on the textfield right's border
	},
	txtField: {
		width        : '100%',
	},
});

function NewsLetter (props) {
	const { classes }         = props;
	const [mailId, setMailID] = useState ("");
	const [result, setResult] = useState (false);
	const title               = 'Subscribe to our newsletter';
	const subTitle            = ['Your passport to knowledge:', ' Latest updates',
		' on scholarships and admissions direct to your inbox weekly.'];
	const placeholder         = 'Enter your email address';
	const btnText             = 'Try It';

	let enrollNewsletter = async () => {
		let regex = /^[\w.-]+@[\w.-]+\.[A-Za-z]{2,}$/;

		if (!regex.test (mailId))
			return window.alert ('Invalid Email');

		try {
			let res = await enrollNewsletterAPI ({ 'email' : mailId });
			if (res.id) {
				setResult (res);
				setMailID ('');
			}
		}
		catch (err) {
			setResult (err.message);//TODO : err.message not working
		}
	} 

	return (
		<Grid container item spacing={2} className={classes.container}>
			<Grid container item spacing={2} className={classes.box}>
				<Grid item xs={false} md={1}>
				</Grid>
				<Grid item xs={12} md={10}>
					<Typography variant="h4" align="center" >
						<b>{title}</b>
					</Typography>
					<Typography variant="h5" align="center" className={classes.textColor}>
						{subTitle[0]}
						<span className={classes.highlighter}> {subTitle[1]} </span>
						{subTitle[2]}
					</Typography>
				</Grid>
			</Grid>
			<Grid container item spacing={0} className={classes.box}>
				<Grid item xs={false} md={3}>
				</Grid>
				{ result === false ? <>
					<Grid item xs={9} md={4}>
						<TextField
							className={classes.txtField}
							id="newsletter-email"
							placeholder={placeholder}
							variant="outlined"
							value={mailId}
							onChange={(e) => {setMailID (e.target.value)}}
						/>
					</Grid>
					<Grid item xs={3} md={2}>
						<Button className={classes.fullCover} variant="contained" color="primary" onClick={() => enrollNewsletter ()}>
							{btnText}
						</Button>
					</Grid> </>
					:
					<Grid item xs={12} md={6}><p>{result && result.id ? 'Thanks for your Registration!' : result}</p></Grid>
				}
			</Grid>
		</Grid>
	);
}

export default withStyles (styles)(NewsLetter);
