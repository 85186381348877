import { NAME_ERR, EMAIL_ERR, CONTENT_ERR } from '../message';

export function validate (data) {
		if (!data.name.length)
				throw new Error (NAME_ERR);

		if (!data.email.length)
				throw new Error (EMAIL_ERR);

		if (!data.content.length)
				throw new Error (CONTENT_ERR);

		return data;
}
