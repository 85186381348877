import React          from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid           from '@material-ui/core/Grid';
import Typography     from '@material-ui/core/Typography';
import SchoolIcon     from '@material-ui/icons/School';
import ExploreIcon    from '@material-ui/icons/Explore';
import PublicIcon     from '@material-ui/icons/Public';
import GroupIcon      from '@material-ui/icons/Group';

const useStyles = makeStyles ((theme) => ({
		root: {
				flexGrow: 1,
		},
		container: {
				padding  : '60px 10px 60px 10px',
				textAlign: 'center',
		},
}));

export default function Features () {
	const classes = useStyles ();
	return (
		<>
			<Grid container spacing={2} className={classes.container}>
				<Grid item md={3} xs={12}>
					<SchoolIcon color='primary' style={{fontSize: 60, backgroundColor: '#e8eaf6', borderRadius: '8px', padding: '10px'}} />
					<Typography variant="h6" component="h6" style={{marginTop: '10px', marginBottom: '10px'}}>
						Built for Students
					</Typography>
					<Typography style={{color: '#718096'}}>
						Integrated data of admissions, full-scholarships and subjects
					</Typography>
				</Grid>
				<Grid item md={3} xs={12}>
					<ExploreIcon color='primary' style={{ fontSize: 60, backgroundColor: '#e8eaf6', borderRadius: '8px', padding: '10px' }} />
					<Typography variant="h6" component="h6" style={{marginTop: '10px', marginBottom: '10px'}}>
						Search and Track
					</Typography>
					<Typography style={{color: '#718096'}}>
						Search  courses and scholarships across the globe
					</Typography>
				</Grid>
				<Grid item md={3} xs={12}>
					<PublicIcon color='primary' style={{ fontSize: 60, backgroundColor: '#e8eaf6', borderRadius: '8px', padding: '10px' }} />
					<Typography variant="h6" component="h6" style={{marginTop: '10px', marginBottom: '10px'}}>
						Live Map
					</Typography>
					<Typography style={{color: '#718096'}}>
						Find on-going fully-funded scholarships and admissions
					</Typography>
				</Grid>
				<Grid item md={3} xs={12}>
					<GroupIcon color='primary' style={{ fontSize: 60, backgroundColor: '#e8eaf6', borderRadius: '8px', padding: '10px' }} />
					<Typography variant="h6" component="h6" style={{marginTop: '10px', marginBottom: '10px'}}>
						Mobility Courses
					</Typography>
					<Typography style={{color: '#718096'}}>
						Search courses for your mobility period across borders
					</Typography>
				</Grid>
			</Grid>
		</>
	);
}
