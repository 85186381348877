import React, { createContext, Component } from 'react';
import { loginAPI, ceaseTokenAPI }         from '../apis/user';

export const UserContext = createContext ();

class UserContextProvider extends Component {
		state = {
				user: null,
		}

		componentDidMount = async () => {
				try {
						if (localStorage.getItem ('overseas.one')) { //intercept
								let _user = JSON.parse (localStorage.getItem ('overseas.one'));
								let resp  = await ceaseTokenAPI (_user.token); //expiry-check
								this.setState ({ user: _user });
						}
				}
				catch (err) {
						localStorage.removeItem ('overseas.one');
						console.log (err);
				}
		}

		checkIn = async (user) => {
				try {
						if (!user)
								return;

						let _user = await loginAPI (user);

						if (!_user.err) {
								this.setState ({ user: _user });
								localStorage.setItem ('overseas.one', JSON.stringify ( _user));
						}
						return (_user);
				}
				catch (err) {
						console.log ("err from context -> ", err);
				}
		}

		/*updatePage = (d) => {
		}*/

		render () {
				return (
						<UserContext.Provider value={{ ...this.state, checkIn: this.checkIn }}>
							{this.props.children}
						</UserContext.Provider>
				);
		}
}

export default UserContextProvider;
