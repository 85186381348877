import React              from 'react';
import { makeStyles }     from '@material-ui/core/styles';
import Grid               from '@material-ui/core/Grid';
import Button             from '@material-ui/core/Button';
import TrackChangesIcon   from '@material-ui/icons/TrackChanges';
import Tooltip            from '@material-ui/core/Tooltip';
import Moment             from 'moment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const useStyles = makeStyles((theme) => ({
		iconCenter: {
				display       : 'flex',
				alignItems    : 'center',
				justifyContent: 'center',
		},
		iconSize: {
				fontSize      : '50px',
		},
}));

export default function Degree ({ data, onMoreClick }) {
		const classes = useStyles ();

		const monthToYear = (m) => {
				let yr = '';
				yr += (data.duration/12).toFixed (0);

				if (data.duration%12)//months
						yr += '.'+(data.duration%12)
				return yr;
		}

		const univRank = (r) => {
			if (r && r > 0)
				return r;
			return 'N/A';
		}

		return (
				<Grid container spacing={1}>
					<Grid item xs={2} className={classes.iconCenter}>
						<AccountBalanceIcon className={classes.iconSize}/>
					</Grid>
					<Grid item xs={8} container>
						<Grid item xs={12}>
							{data.title}
						</Grid>
						<Grid item xs={12}>
							{data.university_ids.map ((u) => { return u.name; }).join ("\n")}
						</Grid>
						<Grid item xs={12}>
							World Ranking : {data.university_ids.map ((u) => { return univRank (u.rank_world); }).join (",")}
						</Grid>
						<Grid item xs={12}>
							Scholarships Available :
							{data.scholarships && data.scholarships.length ? ' '+data.scholarships.length : ' N/A'}
						</Grid>
						<Grid item xs={12}>
							Full/partial funding upto {data.fee_up_to_100 ? '100%' : data.fee_up_to_50 ? '50%' : ' N/A'}
						</Grid>
						<Grid item xs={12}>
							{false && <b>Application Lasts : {Moment (data.deadline).format ('LLLL')}</b>}
						</Grid>
						<Button
							onClick={() => onMoreClick (data.id)}
							variant="outlined"
							size='small'
							color="primary"
							style={{textTransform: 'none', margin: '10px 0 0 0'}}>
							Learn More
						</Button>
					</Grid>
					<Grid item xs={2} container style={{textAlign: 'right'}}>
						<Grid item xs={12}>
							{data.tuition_fee && data.tuition_fee > 0 ? '$ '+data.tuition_fee+' /Year' : ''}
							<br />
							{data.duration > 12 ? monthToYear (data.duration)+" Year" : data.duration+" Month"}
							<br />
							<Tooltip title="Track">
								<TrackChangesIcon />
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
		);
}
