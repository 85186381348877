import React, { createContext, Component } from 'react';
import { scholarshipAPI }                  from '../apis/scholarship';
import { maxPageStore }                    from '../config';

export const ScholarshipContext = createContext ();

class ScholarshipContextProvider extends Component {
		state = {
				scholarshipData : {},
				maxCount        : 0,
				page            : 1, //user's current page i.e. zero
				perPage         : 10,
		}

		componentDidMount = async () => {
				let res = await scholarshipAPI ();
				this.setState ({
						scholarshipData : { 1 : res.data },
						maxCount        : res.count
				});
		}

		//search-btn
		updateState = (d) => {
				if (!d.data.length || !d.count)
						return this.setState ({
								scholarshipData: {},
								page           : 1,
								maxCount       : d.count
						});

				this.setState ({
						scholarshipData: { 1 : d.data},
						page           : 1,
						maxCount       : d.count
				});
		}

		updatePage = (d) => {
				if (!d.data)
						return this.setState ({ page : d.page });

				let _d = {};
				if (Object.keys (this.state.scholarshipData).length < maxPageStore) //storage
						_d = { ...this.state.scholarshipData };

				_d[d.page] = d.data;

				this.setState ({
						scholarshipData: _d,
						page           : d.page
				});
		}

		render () {
				return (
						<ScholarshipContext.Provider value={{ ...this.state, updateScholarship: this.updateState, onPageChange: this.updatePage }}>
							{this.props.children}
						</ScholarshipContext.Provider>
				);
		}
}

export default ScholarshipContextProvider;
