export function retrieveIds (data) {
		let ids = [];

		for (let i = 0; i < data.length; i++) {
				if (data[i].isChecked) {
						ids.push (Number (data[i].id));
				}
		}

		return ids;
}

export function assembleSearch (search, discipline, degree, country) {
		let jsonBody = {};

		if (search.length)
				jsonBody['search'] = search;

		let _discipline = retrieveIds (discipline);
		if (_discipline.length)
				jsonBody['disciplineGroupId'] = _discipline;

		let _degree = retrieveIds (degree);
		if (_degree.length)
				jsonBody['degreeId'] = _degree;

		let _country = retrieveIds (country);
		if (_country.length)
				jsonBody['countryId'] = _country;

		return jsonBody;
}
