import React, { useContext }           from "react";
import { withStyles }                  from "@material-ui/core/styles";
import Grid                            from '@material-ui/core/Grid';
import Button                          from '@material-ui/core/Button';
import Paper                           from '@material-ui/core/Paper';
import TextField                       from '@material-ui/core/TextField';
import CircularProgress                from '@material-ui/core/CircularProgress';

import { MAIL }                        from '../../../utils/message';
import { contactUsAPI }                from '../../../apis/external';
import LogoTravel                      from '../../../assets/images/overseasEduTravel.png';
import { EmailContext }                from '../../../contexts/email';
import { validate }                    from '../../../utils/validate/contactUs';

const styles = theme => ({
		leftPart: {
				borderRadius   : '5px',
				padding        : '10px',
		},
		content: {
				padding        : '10px',
		},
		travelLogo: {
				boxShadow      : '0 5px 5px -5px',
				borderRadius   : '5px 5px 0px 0px',
				borderBottomLeftRadius: '40%',
				width          : '100%',
		},
		formField: {
				width          : '100%',
				paddingTop     : '10px',
		},
		formTextField: {
				width          : '100%',
				marginTop      : '20px',
		},
		formFieldBtn: {
				width          : '100%',
				margin         : '10px 0 10px 0',
		},
});

function ContactUsForm (props) {
		const { classes }                           = props;
		const { name, email, phone, content, load, toggleLoader,
				onMailStateChange, resetMailState } = useContext (EmailContext);

		const onSubmit = async () => {
				try {
						toggleLoader (true);
						let result = await contactUsAPI (validate ({'name': name, 'email': email,
								'phone': phone, 'content': content }));
						resetMailState ();
						alert (MAIL);
				}
				catch (err) {
						toggleLoader (false);
						alert (err.message);
				}
		}

		return (
				<Grid item xs={12} md={4}>
					<Paper elevation={3} className={classes.leftPart}>
					<div className={classes.content}>
						<img
							src={LogoTravel}
							className={classes.travelLogo}
							alt="overseas.one travel abroad"
						/>
						<TextField name="name"  value={name}  label="Full Name *" className={classes.formField} onChange={onMailStateChange} />
						<TextField name="email" value={email} label="Email *"     className={classes.formField} onChange={onMailStateChange} />
						<TextField name="phone" value={phone} label="Phone"       className={classes.formField} onChange={onMailStateChange} />
						<TextField
							className={classes.formTextField}
							onChange={onMailStateChange}
							label="Your Thoughts"
							variant="outlined"
							value={content}
							name="content"
							multiline
							rows={5}
						/>
						<Button variant="contained" color="primary" className={classes.formFieldBtn} onClick={onSubmit} disabled={load}>
							{!load ? 'Contact Us' : <CircularProgress color="primary" size={25} />}
						</Button>
					</div>
					</Paper>
				</Grid>
		);
}

export default withStyles (styles)(ContactUsForm);
