import React             from "react";
import { withStyles }    from "@material-ui/core/styles";
import Grid              from '@material-ui/core/Grid';
import Typography        from '@material-ui/core/Typography';

//import { feeDollar,
//		feeRupee }       from '../../../config';

const styles = theme => ({
		container: {
				width     : '100%',
				padding   : '60px 10px 60px 10px',
		},
		highlighter: {
				color     : '#f9b934',
				fontWeight: 'bold',
		},
		textColor: {
				color     : '#718096',
		},
});

function PaidContent (props) {
		const { classes } = props;
		const title       = 'Upcoming features';
		const subTitle    = ['Personal growth benefits for', 'future scholars'];
		const list        = [
				'Save and track deadlines of various courses and scholarships',
				'Get checked your motivational letter from experts',
				'Regular useful suggestions and personal guidance',
				'Suggestions to choose a programe with a balance in language, duration and tuition fees'
		];

		return (
				<Grid container spacing={2} className={classes.container}>
					<Grid item md={8} xs={12}>
						<Typography variant="h4">
							<b>{title}</b>
						</Typography>
						<Typography variant="h5">
							{subTitle[0]}
							<span className={classes.highlighter}> {subTitle[1]} </span>
						</Typography>
						<Typography variant="h6" className={classes.textColor}>
						<ul>
						{
								list.map ((item, i) => (
									<li key={i}>{item}</li>
								))
						}
						</ul>
						</Typography>
					</Grid>
				</Grid>
		);
}
/*<Grid container item md={4} xs={12} align='center' justify="center" direction="column">
	<Grid>
		<Typography variant="h3" className={classes.textColor}>
			{false && <b>{feeDollar} USD/ ₹{feeRupee}</b>}
		</Typography>
	</Grid>
</Grid>*/

export default withStyles (styles)(PaidContent);
