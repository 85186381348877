import React             from "react";
import { withStyles }    from "@material-ui/core/styles";
import Grid              from '@material-ui/core/Grid';

import NavigationBar     from '../home/molecules/navigationBar';
import Idea              from './atoms/idea';
import Suffer            from './atoms/suffer';
import Footer            from '../home/atoms/footer';

const styles = theme => ({
		root: {
				flexGrow       : 1,
		},
		container: {
				padding        : '60px 10px 60px 10px',
		},
		ideaBox: {
				margin         : '0',
				width          : '100%',
				backgroundColor: '#F7F9FA',
				marginTop      : '40px'
		},
		sufferBox: {
				margin         : '0',
				width          : '100%',
				marginTop      : '40px',
				marginBottom   : '40px',
		},
});

class About extends React.Component {
		constructor (props) {
				super (props);
				this.state = {
						value : 0,
				};
		}

		componentDidMount () {
				document.title = "About Us | Overseas.one";
		}

		render (props) {
				const { classes } = this.props;
				return (
						<div className={classes.root}>
							<NavigationBar />
							<Grid container spacing={2} className={classes.ideaBox}>
								<Grid item xs={false} md={2}>
								</Grid>
								<Grid container item xs={12} md={8}>
									<Idea />
								</Grid>
								<Grid item xs={false} md={2}>
								</Grid>
							</Grid>
							<Grid container spacing={2} className={classes.sufferBox}>
								<Grid item xs={false} md={2}>
								</Grid>
								<Grid container item xs={12} md={8}>
									<Suffer />
								</Grid>
								<Grid item xs={false} md={2}>
								</Grid>
							</Grid>
							<Footer />
						</div>
				);
		}
}

export default withStyles (styles)(About);
