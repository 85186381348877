export function decompress (compressed) {
		return compressed;

		/*let x        = [];
		let segments = compressed.split (/(,)/);

		while (segments.length) {
				let first = segments.shift ();
				console.log ("first = ", first);
				x.unshift (first);
				let last = segments.pop ();
				console.log ("last = ", last);
				x.push (last);
		}
		return x.join ();*/
}
