import React, { useContext }   from 'react';
import { makeStyles }          from '@material-ui/core/styles';
import Paper                   from '@material-ui/core/Paper';
import InputBase               from '@material-ui/core/InputBase';
import Divider                 from '@material-ui/core/Divider';
import Button                  from '@material-ui/core/Button';
import SearchIcon              from '@material-ui/icons/Search';
import { Link, useHistory }    from 'react-router-dom';

import { SearchStringContext } from '../../../contexts/filters/searchString';

const useStyles = makeStyles ((theme) => ({
		root: {
				padding   : '2px 4px',
				display   : 'flex',
				//alignItems: 'center',
				margin    : "auto",
		},
		input: {
				marginLeft: theme.spacing(1),
				flex      : 1,
		},
		iconButton: {
				padding   : 10,
				textTransform : 'none',
		},
		divider: {
				height    : 35,
				margin    : 4,
		},
		subTitle: {
				color     : '#718096',

		},
}));

//Do not try to fit the args int GET params
export default function CustomizedInputBase () {
		const classes                         = useStyles ();
		let history                           = useHistory ();
		var { searchString, setSearchString } = useContext (SearchStringContext);

		const onKeyPress = (e) => {
				if (e.key === 'Enter')
						history.push ('/app/scholarships');
		}

		return (
				<Paper className={classes.root}>
					<InputBase
						autoFocus
						className={classes.input}
						placeholder="Type in your dream course"
						inputProps={{ 'aria-label': 'Type in your dream course' }}
						onChange={(e) => setSearchString (e.target.value, { 'prevRoute': '/' })}
						onKeyPress={(e) => onKeyPress (e)}
					/>
					<Divider className={classes.divider} orientation="vertical" />
					<Button component={Link} to={'/app/scholarships'} className={classes.iconButton} aria-label="search" >
						<SearchIcon color='primary'/>
						<span className={classes.subTitle}> Search </span>
					</Button>
				</Paper>
		);
}
