import React                  from "react";
import { withStyles }         from "@material-ui/core/styles";
import Grid                   from '@material-ui/core/Grid';
import queryString            from "query-string";

import NavigationBar          from '../home/molecules/navigationBar';
import Detail                 from './molecules/detail';
import CommentForm            from './molecules/commentForm';
import ListComment            from './atoms/listComment';
import Footer                 from '../home/atoms/footer';
import { exchangeSubjectAPI } from '../../apis/exchange';
import { NOTFOUND }           from '../../utils/message';

const styles = theme => ({
		root: {
				flexGrow : 1,
		},
		container: {
				padding  : '60px 10px 60px 10px',
		},
		txtCenter: {
				textAlign: 'center',
				margin   : '150px 0 150px 0',
		},
		gridWidth: {
				width    : '100%',
		},
});

const commentsData = [
		{
				'created_at' : '2022-04-25T15:48:40.465+00:00',
				'comment': 'The prof is excellent. She gives good marks for writing well. Since it\'s offline computer based cheating is not possible.',
				'cid': '6266c2d866cbb42c7ef7f9ab',
				'rate': 5,
				'user_id': '606e3b98083779355a7c049d',
				'first_name':'Maria',
				'last_name':'Trabattonni',
				'score':5, //machine learning algo based
		},
		{
				'created_at' : '2022-04-25T15:48:40.465+00:00',
				'comment': 'Good to go for non-attending student.',
				'cid': '6266c2d866cbb42c7ef7f9ab',
				'rate': 4.5,
				'user_id': '606e3b98083779355a7c049d',
				'first_name':'Ella Ellis',
				'last_name':'Petousis',
				'score':5, //machine learning algo based
		},
		{
				'created_at' : '2022-04-25T15:48:40.465+00:00',
				'comment': 'The traditional capitalist method is on eof the best. The prof. prefers to go offline. Nice to hav a subject in Erasmus.',
				'cid': '6266c2d866cbb42c7ef7f9ab',
				'rate': 3,
				'user_id': '606e3b98083779355a7c049d',
				'first_name':'Ashwini',
				'last_name':'Bral',
				'score':5, //machine learning algo based
		},
		{
				'created_at' : '2022-04-25T15:48:40.465+00:00',
				'comment': 'Studied for last few days and got really good score. Go for it.',
				'cid': '6266c2d866cbb42c7ef7f9ab',
				'rate': 4,
				'user_id': '606e3b98083779355a7c049d',
				'first_name':'Valeriya',
				'last_name':'Faraoni',
				'score':5, //machine learning algo based
		},
		{
				'created_at' : '2022-04-25T15:48:40.465+00:00',
				'comment': 'Businees ideas could grow from this text book in reality.',
				'cid': '6266c2d866cbb42c7ef7f9ab',
				'rate': 3.5,
				'user_id': '606e3b98083779355a7c049d',
				'first_name':'Katherina',
				'last_name':'Halovok',
				'score':5, //machine learning algo based
		},
];

class CourseReport extends React.Component {
		constructor (props) {
				super (props);

				this.state = {
						error: false,
						data : null,
				};
		}

		async componentDidMount () {
				try {
						const param    = queryString.parse (window.location.search);
						let resp       = await exchangeSubjectAPI (param);
						document.title = resp.title +" | Overseas.one";
						this.setState ({ data: resp });
				}
				catch (err) {
						this.setState ({ error : true });
				}
		}

		render (props) {
				const { classes } = this.props;

				if (this.state.error || !this.state.data)
						return (
								<div className={classes.root}>
										<NavigationBar />
										<Grid container spacing={2} className={classes.gridWidth}>
												<Grid item xs={false} md={2}>
												</Grid>
												<Grid item xs={12} md={8} className={classes.txtCenter}>
														<h1>{ NOTFOUND }</h1>
												</Grid>
										</Grid>
										<Footer />
								</div>
						);

				return (
						<div className={classes.root}>
							<NavigationBar />
							<Grid container spacing={2} className={classes.gridWidth}>
								<Grid item xs={false} md={2}>
								</Grid>
								<Grid container item xs={12} md={8}>
										<Detail data={this.state.data} />
								</Grid>
								<Grid item xs={false} md={2}>
								</Grid>
							</Grid>
							<Grid container spacing={2} className={classes.gridWidth}>
								<Grid item xs={false} md={2}>
								</Grid>
								<Grid container item xs={12} md={8}>
									<CommentForm />
								</Grid>
								<Grid item xs={false} md={2}>
								</Grid>
							</Grid>
							<Grid container spacing={2} className={classes.gridWidth}>
								<Grid item xs={false} md={2}>
								</Grid>
								<Grid container item xs={12} md={8}>
									<ListComment
										id={this.state.data._id}
									/>
								</Grid>
								<Grid item xs={false} md={2}>
								</Grid>
							</Grid>
							<Footer />
						</div>
				);
		}

}

export default withStyles (styles)(CourseReport);
