import React, { useContext }  from 'react';
import { makeStyles }         from '@material-ui/core/styles';
import Paper                  from '@material-ui/core/Paper';
import Grid                   from '@material-ui/core/Grid';

import Degree                 from './degree';
import ModalLearnMore         from '../atoms/modalLearnMore';
import { ScholarshipContext } from '../../../contexts/scholarship';

const useStyles = makeStyles((theme) => ({
		root: {
				flexGrow : 1,
		},
		paper: {
				padding  : theme.spacing(1),
				color    : theme.palette.text.secondary,
		},
		leftPane: {
				color    : theme.palette.text.secondary,
		},
		eachDegree: {
				padding  : theme.spacing (2),
				color    : theme.palette.text.secondary,
		},
		noData: {
			textAlign    : 'center',
			width        : '100%',
		},
}));

export default function CenterPane () {
		const [open, setOpen]                = React.useState ({});
		const classes                        = useStyles ();
		var { scholarshipData, page }        = useContext (ScholarshipContext);

		const onMoreClick = (_id) => {
			for (const ele of scholarshipData[page]) {
				if (ele.id === _id) {
					setOpen (ele);
					break;
				}
			}
			//const found = scholarshipData[page].some (ele => ele.id === _id);
		}

		const handleModalClose = () => {
				setOpen ({});
		}

		if (!Object.keys (scholarshipData).length)
				return (
						<div className={classes.noData}>
							<h2>No Data Found</h2>
						</div>
				);

		return (
				<>
					{
						scholarshipData[page].map ((bursary)=> (
							<Grid item xs={12} key={bursary.id}>
								<Paper className={classes.eachDegree}>
									<Degree
										data={bursary}
										onMoreClick={onMoreClick}
									/>
								</Paper>
							</Grid>
						))
					}
					<ModalLearnMore
						open={open}
						handleModalClose={handleModalClose}
					/>
				</>
		);
}
