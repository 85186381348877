import React               from 'react';
import { makeStyles }      from '@material-ui/core/styles';
import Snackbar            from '@material-ui/core/Snackbar';
import IconButton          from '@material-ui/core/IconButton';
import CloseIcon           from '@material-ui/icons/Close';


const useStyles = makeStyles ((theme) => ({
}));

export default function Messenger ({ open, handleMesenger, msg }) {
		const classes = useStyles ();

		return (
				<Snackbar
				anchorOrigin={{
					vertical  : 'top',
					horizontal: 'right',
				}}
				open={open}
				autoHideDuration={6000}
				onClose={handleMesenger}
				message={msg}
				action={
					<React.Fragment>
						<IconButton size="small" aria-label="close" color="inherit" onClick={handleMesenger}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</React.Fragment>
				}
				/>
		);
}
