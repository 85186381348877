import React ,{ useContext,
	useEffect }               from 'react';
import { makeStyles }         from '@material-ui/core/styles';
import Button                 from '@material-ui/core/Button';
import Search                 from '@material-ui/icons/Search';

import { DisciplineContext }  from '../../../contexts/filters/discipline';
import { DegreeContext }      from '../../../contexts/filters/degree';
import { CountryContext }     from '../../../contexts/filters/country';
import { ScholarshipContext } from '../../../contexts/scholarship';
import { SearchStringContext } from '../../../contexts/filters/searchString';

import { retrieveIds,
		assembleSearch }      from '../../../utils/metaDataUtils';
import { scholarshipAPI }     from '../../../apis/scholarship';

const useStyles = makeStyles ((theme) => ({
		searchBtn: {
				textTransform: 'none',
				height       : "100%" ,
				width        : "100%",
		},
}));

export default function SearchButton () {
		const classes                                      = useStyles ();
		var { disciplineData, toggleCheckbox, resetDis }   = useContext (DisciplineContext);
		var { degreeData, toggleDegreeChkbox, resetDeg }   = useContext (DegreeContext);
		var { countryData, toggleCountryChkbox, resetCon } = useContext (CountryContext);
		var { scholarshipData, updateScholarship }         = useContext (ScholarshipContext);
		var { searchString, prevPath, setSearchString,
				resetSea, pathReset }                      = useContext (SearchStringContext);

		/* componentDidMount */
		useEffect (() => {
				if (prevPath === '/') { // from home
						if (searchString.length) {
								onSearch ();
								pathReset ();
						}
				}
				else {
						resetSea ();
						resetDis ();
						resetDeg ();
						resetCon ();
				}
		}, []);

		const onSearch = async () => {
				try {
						let params = assembleSearch (searchString, disciplineData, degreeData, countryData);
						let resp   = await scholarshipAPI (params);
						updateScholarship (resp);
				}
				catch (e) {
						console.error ('grants -> searchBtn -> Error :', e);
				}
		}

		return (
				<Button
					variant="contained"
					color="primary"
					className={classes.searchBtn}
					size="large"
					startIcon={<Search />}
					onClick={onSearch}
				>
					Search
				</Button>
		);
}
