import React, { useContext }     from "react";
import { Button }                from '@material-ui/core';
import { Link }                  from "react-router-dom";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { UserContext }           from '../../../contexts/user';

function AccountButton () {
		var { user } = useContext (UserContext);

		return (
				<Button
					color="primary"
					variant="contained"
					startIcon={<AccountCircleOutlinedIcon />}
					href={window.location.origin +"/dashboard"}>
						<b style={{whiteSpace:'nowrap', textTransform: 'none'}}>
							Hi!
							&nbsp;
							{user && user.first_name ? user.first_name.charAt(0).toUpperCase () : ''}
							{user && user.last_name ? user.last_name.charAt(0).toUpperCase () : ''}
						</b>
				</Button>
		);
}

export default AccountButton;
