import React          from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid           from '@material-ui/core/Grid';

import NavigationBar  from "./molecules/navigationBar";
import Welcome        from './atoms/welcome';
import ScholarshipMap from './atoms/scholarshipMap';
import Features       from "./atoms/features";
import Story          from './molecules/story';
import NewsLetter     from './atoms/newsLetter';
import PaidContent    from './atoms/paidContent';
import Footer         from './atoms/footer';

const styles = theme => ({
		root: {
				flexGrow: 1,
		},
});

class Home extends React.Component {
		constructor (props) {
				super (props);
				this.state = {
						value : 0,
				};
		}

		componentDidMount () {
				document.title = "Find Search Track Scholarships for your dream study abroad | overseas.one";
		}

		render (props) {
				const { classes } = this.props;
				return (
						<div className={classes.root}>
							<NavigationBar />
							<Grid container spacing={2} style={{width: '100%'}}>
								<Grid item xs={false} md={1}>
								</Grid>
								<Grid container item xs={12} md={10}>
									<Welcome />
								</Grid>
								<Grid item xs={false} md={1}>
								</Grid>
							</Grid>
							<Grid container spacing={2} style={{margin: '0', width: '100%', backgroundColor: '#F7F9FA', marginTop: '80px'}}>
								<ScholarshipMap />
							</Grid>
							<Grid container spacing={2} style={{width: '100%'}}>
								<Grid item xs={false} md={1}>
								</Grid>
								<Grid container item xs={12} md={10}>
									<Features />
								</Grid>
								<Grid item xs={false} md={1}>
								</Grid>
							</Grid>
							<Grid container spacing={2} style={{margin: '0',width: '100%', backgroundColor: '#F7F9FA', marginTop: '40px'}}>
								<Grid item xs={false} md={1}>
								</Grid>
								<Grid container item xs={12} md={10}>
									<Story />
								</Grid>
								<Grid item xs={false} md={1}>
								</Grid>
							</Grid>
							<Grid container spacing={2} style={{width: '100%'}}>
								<Grid item xs={false} md={1}>
								</Grid>
								<Grid container item xs={12} md={10}>
									<NewsLetter />
								</Grid>
								<Grid item xs={false} md={1}>
								</Grid>
							</Grid>
							<Grid container spacing={2} style={{margin: '0', width: '100%', backgroundColor: '#F7F9FA'}}>
								<Grid item xs={false} md={1}>
								</Grid>
								<Grid container item xs={12} md={10}>
									<PaidContent />
								</Grid>
							</Grid>
							<Footer />
						</div>
				);
		}
}

export default withStyles (styles)(Home);
