import React, { useContext, memo } from "react";
import Grid                        from '@material-ui/core/Grid';
import { withStyles }              from "@material-ui/core/styles";
import { ZoomableGroup,
		ComposableMap, Geographies,
		Geography, Graticule
} from "react-simple-maps";

import GeoData                     from '../../../assets/maps/world-110m.json';
import { DISCLAIMER }              from '../../../utils/message';
import { MapContext }              from '../../../contexts/map';

const styles = theme => ({
		container: {
				//padding  : '0px 0px 30px 0px', //top left bottom rigtht
				width    : '100%',
		},
		map: {
				width    : "100%",
				//height   : "auto",
				outline  : 'none',
		},
		disclaimer: {
				fontSize : 'smaller',
				fontStyle: 'italic',
				textAlign: 'center',
		},
});

function WorldMap (props) {
		const { classes }                   = props;
		var { isoPin, mapLabel, selectPin } = useContext (MapContext);
		//stroke is the country boundary color

		const onMapClick = (g)=> {
				if (mapLabel === 'grant')
						selectPin (g.properties.ISO_A2);
		}

		return (
				<div className={classes.container}>
				<Grid container spacing={2} className={classes.container}>
					<Grid item xs={false} md={1}>
					</Grid>
					<Grid item xs={12} md={10}>
					<div className={classes.disclaimer}><h4>The highlighted zones include all courses.</h4></div>
					<ComposableMap data-tip="" projectionConfig={{ scale: 110 }} height={300} className={classes.map}>
						<Graticule stroke="#D6D6DA" strokeWidth={0.5} />
						<ZoomableGroup>
							<Geographies geography={GeoData}>
								{({ geographies }) =>
									geographies.map(geo => (
										<Geography
											stroke="#E8E8E8"
											key={geo.rsmKey}
											geography={geo}
											onClick={() => onMapClick(geo)}
											onMouseEnter={() => {
												props.setTooltipContent (geo.properties.NAME);
											}}
											onMouseLeave={() => {
												props.setTooltipContent ("");
											}}
											style={{
												default: {
													fill   : `${isoPin.includes (geo.properties.ISO_A2) ? "#4B0082" : "#D6D6DA"}`,
													outline: "none",
												},
												hover: {
													fill   : "#F9B934", outline: "none"
												},
											}}
										/>
									))
								}
							</Geographies>
						</ZoomableGroup>
					</ComposableMap>
					<div className={classes.disclaimer}>{ DISCLAIMER }</div>
					</Grid>
				</Grid>
				</div>				
		);
}

export default withStyles (styles)(memo (WorldMap));
