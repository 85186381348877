import React, { useContext } from "react";
import { withStyles }        from "@material-ui/core/styles";
import Grid                  from '@material-ui/core/Grid';

import { NOTFOUND }          from '../../../utils/message';
import { CountryContext }    from '../../../contexts/filters/country';
import { MapContext }        from '../../../contexts/map';

const styles = theme => ({
		container: {
				margin   : 0,
				width    : '100%',
				padding  : '0px 0px 60px 0px',
		},
		italic: {
				fontStyle: 'italic',
		},
});

function CountryListing (props) {
		const { classes }   = props;
		var { countryData } = useContext (CountryContext);
		var { isoPin }      = useContext (MapContext);
		let liveAdmName     = countryData.filter (item => isoPin.includes (item.iso_code));

		return (
				<Grid container spacing={2} className={classes.container}>
					<Grid item xs={false} md={1}>
					</Grid>
					<Grid item xs={12} md={10}>
						Countries listed above -
						<span className={classes.italic}>
							{liveAdmName.length ? ' '+liveAdmName.map (item => item.name).join (', '): ' '+NOTFOUND }
						</span>
					</Grid>
				</Grid>
		);
}

export default withStyles (styles)(CountryListing);
