import React          from "react";

class Blog extends React.Component {
	/*constructor (props) {
		super (props);
	}*/

	componentDidMount () {
		window.location.reload ();
	}

	render (props) {
		return (
			<></>
		);
	}
}

export default Blog;
