import { useContext, useEffect } from "react";
/* A constructor to set
 * MapContext "mapLabel":'grant'
 */

import { MapContext }            from '../../../contexts/map';

function SetMapLabel () {
	var { handleMapLabel } = useContext (MapContext);

	useEffect(() => {
		handleMapLabel ('grant');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (null);
}

export default SetMapLabel;
