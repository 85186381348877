export async function mapAPI (jsonBody) {
		try {
				let url = window.location.origin + '/map';
				const response = await fetch (url, {
						method : 'POST',
						headers: {
								'Content-Type': 'application/json',
						},
						body   : JSON.stringify (jsonBody),
				});

				if (!response.ok)
						throw Error (response.statusText);

				return response.json ();
		}
		catch (e) {
				throw Error (e);
		}
}

export async function fullyFundedAPI (jsonBody) {
		try {
				let url = window.location.origin + '/map/fullyfunded';
				const response = await fetch (url, {
						method : 'POST',
						headers: {
								'Content-Type': 'application/json',
						},
						body   : JSON.stringify (jsonBody),
				});

				if (!response.ok)
						throw Error (response.statusText);

				return response.json ();
		}
		catch (e) {
				throw Error (e);
		}
}

export async function fullyFundedPerCountryAPI (jsonBody) {
		try {
				if (!jsonBody.isoCode)
						throw new Error ('Not Found: Country code');

				let url = window.location.origin + '/map/fullyfunded/' + jsonBody.isoCode;
				const response = await fetch (url, {
						method : 'POST',
						headers: {
								'Content-Type': 'application/json',
						},
						body   : JSON.stringify (jsonBody),
				});

				if (!response.ok)
						throw Error (response.statusText);

				return response.json ();
		}
		catch (e) {
				throw Error (e);
		}
}
