import React                  from "react";
import { withStyles }         from "@material-ui/core/styles";
import Typography             from '@material-ui/core/Typography';
import TextField              from '@material-ui/core/TextField';
import FormControl            from '@material-ui/core/FormControl';
import InputLabel             from '@material-ui/core/InputLabel';
import Input                  from '@material-ui/core/Input';
import InputAdornment         from '@material-ui/core/InputAdornment';
import IconButton             from '@material-ui/core/IconButton';
import Visibility             from '@material-ui/icons/Visibility';
import VisibilityOff          from '@material-ui/icons/VisibilityOff';

import Checkbox               from '@material-ui/core/Checkbox';

const styles = theme => ({
		root: {
				flexGrow      : 1,
		},
		logoTitle: {
				margin        : '30px',
				display       :'flex',
				justifyContent:'center',
				alignItems    :'center',
		},
		grid: {
				width         : '100%',
		},
		gridWidth: {
				width         : '100%',
				display       :'flex',
				justifyContent:'center',
				alignItems    :'center',
		},
		noCenter: {
				display       :'flex',
				textAlign     :'start',
		},
});

function RegisterForm (props) {
		const { classes, firstName, lastName, email, passwd, visibility, 
				rePasswd, phone, checked, fieldChange } = props;

		return (
			<>
				<Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
					Sign up for your account
				</Typography>
				<br />
				<FormControl fullWidth  variant="standard">
					<TextField fullWidth id="firstName" name="firstName" label="First Name *"
						variant="standard" value={firstName}
						onChange={(e) => fieldChange (e.target.name, e.target.value)}
					/>
				</FormControl>
				<br />
				<FormControl fullWidth  variant="standard">
					<TextField fullWidth id="lastName" name="lastName" label="Last Name *"
						variant="standard" value={lastName}
						onChange={(e) => fieldChange (e.target.name, e.target.value)}
					/>
				</FormControl>
				<br />
				<FormControl fullWidth  variant="standard">
					<TextField fullWidth id="email" name="email" label="Email *"
						variant="standard" value={email}
						onChange={(e) => fieldChange (e.target.name, e.target.value)}
					/>
				</FormControl>
				<br />
				<FormControl fullWidth  variant="standard">
					<InputLabel htmlFor="std-adornment-password">Password *</InputLabel>
					<Input
						id="std-adornment-password"
						type={visibility ? 'text' : 'password'}
						value={passwd}
						name="passwd"
						onChange={(e) => fieldChange (e.target.name, e.target.value)}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => fieldChange ('visibility', !visibility)}
								>
									{visibility ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						label="Password"
					/>
				</FormControl>
				<br />
				<FormControl fullWidth  variant="standard">
					<InputLabel htmlFor="std-adornment-retype-password">Retype Password *</InputLabel>
					<Input
						id="std-adornment-retype-password"
						type='password'
						value={rePasswd}
						name="rePasswd"
						onChange={(e) => fieldChange (e.target.name, e.target.value)}
						label="Password"
					/>
				</FormControl>
				<br />
				<FormControl fullWidth  variant="standard">
					<TextField fullWidth id="mobile" label="Phone Number"
						variant="standard" name='phone' value={phone}
						onChange={(e) => fieldChange (e.target.name, e.target.value)}
					/>
				</FormControl>
				<br /><br />
				<div className={classes.noCenter}>
					<Checkbox
						size="small" color="primary"
						checked={checked}
						onChange={() => fieldChange ('checked', !checked)}
						inputProps={{ 'aria-label': 'primary checkbox' }}
					/>
					Yes! Send me news and offers from Overseas.one about products, events, recommendations and more.
				</div>
				<p className={classes.noCenter}>
					By signing up, I accept the Overseas.one Terms of Service and acknowledge the Privacy Policy.
				</p>
			</>
		);
};

export default withStyles (styles)(RegisterForm);
