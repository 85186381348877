import React             from "react";
import { withStyles }    from "@material-ui/core/styles";

const styles = theme => ({
		container: {
				color    : '#718096',
				textAlign: 'justify',
		},
});

function Idea (props) {
		const { classes } = props;

		return (
			<div className={classes.container}>
				<h1>How did we start The Idea ?</h1>
				<p>
					Searching for the necessity has always been one of the complex tasks.
					Students search for scholarships every year, due to “n” number of reasons.
					We observed the students used to rely on blogs, a few scholarship sites, YouTube videos,
					Instagram or twitter posts and more.
					Each one of them provided only one sided picture.
					For example, a YouTube video will showcase his/her face and how their experience was.
					Unfortunately, a fresher actually needs the eligibility and steps to complete the procedure.
					Similar issues happen to be available in each source. The whole procedure was-
				</p>
				<ul>
					<li>Time consuming,</li>
					<li>Remembering each and every application dates or deadlines, and</li>
					<li>Each day’s tedious task is to search each country, then its universities and its courses.</li>
				</ul>
				<p>
					Thus, we decided to fill this gap and create a single source as a starter to search
					overseas courses, admissions and scholarships.
				</p>
			</div>
		);
}

export default withStyles (styles)(Idea);
