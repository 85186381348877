import React                           from 'react';
import { withStyles }                  from '@material-ui/core/styles';
import CircularProgress                from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
		loader: {
				display       : 'flex',
				alignItems    : 'center',
				justifyContent: 'center',
		},
});

function WaitingLoader (props) {
		const { classes } = props;

		return (
			<div className={classes.loader}>
				<CircularProgress color="primary"/>
				<span className={classes.loader}> &nbsp; Please wait... </span>
			</div>
		);
}

export default withStyles (styles)(WaitingLoader);
