import { FNAME, LNAME, NO_SPECIAL, MAILID,
		MAIL_INVALID, PASSWD,  RETYPE_PASS,
		PASSWD_REGEX, PASS_MISMATCH, PHONE,
		PHONE_INVALID }                     from '../message';
//XXX : Do not check for foreign lang alphabets, since too many

export function validate (d) {
		if (!d.first_name.length)
				throw new Error (FNAME);

		d.first_name = d.first_name.trim ();
		d.first_name = d.first_name.charAt(0).toUpperCase() + d.first_name.slice(1);

		if (!d.last_name.length)
				throw new Error (LNAME);

		d.last_name = d.last_name.trim ();
		d.last_name = d.last_name.charAt(0).toUpperCase() + d.last_name.slice(1);

		if (!d.email.length)
				throw new Error (MAILID);

		if (!(/^\S+@\S+\.\S+$/).test (d.email))
				throw new Error (MAIL_INVALID);

		d.email = d.email.trim ();

		if (!d.passwd.length)
				throw new Error (PASSWD);

		let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
		if (!re.test (d.passwd))
				throw new Error (PASSWD_REGEX);

		if (d.passwd !== d.rePasswd)
				throw new Error (PASS_MISMATCH);

		if (d.phone.length) {
				d.phone = d.phone.replaceAll(' ', '');
				if (d.phone.length < 12)
						throw new Error (PHONE);

				if (!Number (d.phone))
						throw new Error (PHONE_INVALID);

				d.phone = Number (d.phone);
				if (typeof (d.phone) !== 'number')
						throw new Error (PHONE_INVALID);

		}

		//TODO : same validation on server side as well, since any one might come directly from API
		return d;
}
