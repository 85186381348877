import React ,{ useContext,
	useEffect }                from 'react';
import { makeStyles }          from '@material-ui/core/styles';
import Button                  from '@material-ui/core/Button';
import Search                  from '@material-ui/icons/Search';

import { SearchStringContext } from '../../../contexts/filters/searchString';
import { DisciplineContext }   from '../../../contexts/filters/discipline';
import { DegreeContext }       from '../../../contexts/filters/degree';
import { CountryContext }      from '../../../contexts/filters/country';
import { ExchangeContext }     from '../../../contexts/exchange';

import { retrieveIds,
		assembleSearch }       from '../../../utils/metaDataUtils';
import { exchangeAPI }         from '../../../apis/exchange';

const useStyles = makeStyles ((theme) => ({
		searchBtn: {
				textTransform: 'none',
				height       : "100%" ,
				width        : "100%",
		},
}));

export default function SearchButton () {
		const classes                                      = useStyles ();
		var { searchString, setSearchString, resetSea }    = useContext (SearchStringContext);
		var { disciplineData, toggleCheckbox, resetDis }   = useContext (DisciplineContext);
		var { degreeData, toggleDegreeChkbox, resetDeg }   = useContext (DegreeContext);
		var { countryData, toggleCountryChkbox, resetCon } = useContext (CountryContext);
		var { exchangeData, updateExchange }               = useContext (ExchangeContext);

		/* componentDidMount
		 * No data will ever come from search home page
		 * or other page
		 */
		useEffect (() => {
				resetSea ();
				resetDis ();
				resetDeg ();
				resetCon ();
		}, []);

		const onSearch = async () => {
				try {
						let params = assembleSearch (searchString, disciplineData, degreeData, countryData);
						let resp   = await exchangeAPI (params);
						updateExchange (resp);
				}
				catch (e) {
						console.error ('grants -> searchBtn -> Error :', e);
				}
		}

		return (
				<Button
					variant="contained"
					color="primary"
					className={classes.searchBtn}
					size="large"
					startIcon={<Search />}
					onClick={() => onSearch ()}
				>
					Search
				</Button>
		);
}
