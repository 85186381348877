import { springColor, summerColor,
        autumnColor }              from '../config';

export function semesterTrackUnit (s) {
		/* 2 Sem/yr */
		if (s.track === 2 && s.unit === 1)
				return { 'n': 'I st', 'c'  : autumnColor };
		if (s.track === 2 && s.unit === 2)
				return { 'n': 'II nd', 'c' : springColor };

		/* 3 Sem/yr */
		if (s.track === 3 && s.unit === 1)
				return { 'n': 'I st', 'c'  : autumnColor };
		if (s.track === 3 && s.unit === 2)
				return { 'n': 'II nd', 'c' : springColor };
		if (s.track === 3 && s.unit === 3)
				return { 'n': 'III rd', 'c': summerColor };
}
