import React                 from "react";
import { withStyles }        from "@material-ui/core/styles";
import { Link }              from 'react-router-dom';
import Grid                  from '@material-ui/core/Grid';
import Button                from '@material-ui/core/Button';
import Typography            from '@material-ui/core/Typography';

import GreyWorldMap          from '../../../assets/images/greyWorldMap.png';

const styles = theme => ({
		container: {
				padding       : '60px 0px 80px 0px',
				textAlign     : 'center',
				width         : '100%',
		},
		highlighter: {
				color         : 'indigo',
				fontWeight    : 'bold',
		},
		titleColor: {
				color         : '#2d3748',
				fontWeight    : 'bold',
		},
		btnText: {
				textTransform : 'none', //capitals
				textDecoration: 'inherit',//underline
				color         : 'inherit',//font-color
		},
		background: {
				background    : `url(${GreyWorldMap}) no-repeat center`,
				width         : '100%',
		},
});

function ScholarshipMap (props) {
		const { classes }      = props;
		const title            = ['Find | Search | Track', 'Fully Funded Scholarship'];
		const subTitle         = ['Public | Private | Autonomous', 'Institutions across the Globe'];
		const subSubTitle      = ['Let\'s Build the Community together', 'Come and Share the Experience'];

		return (
				<div className={classes.background}>
					<Grid container spacing={2} className={classes.container}>
						<Grid item md={12} xs={12}>
							<Typography variant="h5" className={classes.titleColor}>
								<span className={classes.highlighter}>{title[0]}</span>
								<br />
								{title[1]}
								<br />
								<br />
								<span className={classes.highlighter}>{subTitle[0]}</span>
								<br />
								{subTitle[1]}
							</Typography>
							<br />
							<Typography variant="h4" className={classes.titleColor}>
								{subSubTitle[0]}
								<br />
								{subSubTitle[1]}
							</Typography>
							<br />
							<Button variant="contained" color="primary">
								<Link to={'/app/fully-funded-scholarships-map'} className={classes.btnText}> More Information </Link>
							</Button>
					</Grid>
				</Grid>
				</div>
		);
}

export default withStyles (styles)(ScholarshipMap);
