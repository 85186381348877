import React, { createContext, Component } from 'react';
import { mapAPI, fullyFundedAPI }          from '../apis/map';

export const MapContext = createContext ();

class MapContextProvider extends Component {
		state = {
				isoPin  : [],
				mapLabel: 'adm', //adm|grant
				pin     : '',    //any isoPin of nation
				loader  : false,
		}

		componentDidMount = async () => {
				try {
						let result = await mapAPI ();
						this.setState ({ 'isoPin': result });
				}
				catch (err) {
						console.error (err); //TODO: show error to user
				}
		}
		updateMap = () => {}

		handleMapLabel = async (val, filterParam) => {
				try {
						let result = [];
						switch (val.toLowerCase ()) {
								case 'adm'  : result = await mapAPI ();           break;
								case 'grant': result = await fullyFundedAPI ({}); break;
								//set filterParam afterwards
								default : console.log ('switch No match Found : ', val);
						}
						this.setState ({ 'mapLabel': val, 'isoPin': result });
				}
				catch (err) {
						console.error (err);
				}
		}

		handleLoader = () => {
			this.setState ({ 'loader' : !this.state.loader });
		}

		selectPin = (pin) => {
			this.setState ({ pin });
		}

		render () {
				return (
						<MapContext.Provider value={{
								...this.state,
								updateMap: this.updateMap,
								handleMapLabel: this.handleMapLabel,
								handleLoader  : this.handleLoader,
								selectPin     : this.selectPin
						}}>
							{this.props.children}
						</MapContext.Provider>
				);
		}
}

export default MapContextProvider;
