import React, { useContext, useState,
		useEffect, memo }              from 'react';
import { makeStyles }                  from '@material-ui/core/styles';
import Dialog                          from '@material-ui/core/Dialog';
import AppBar                          from '@material-ui/core/AppBar';
import Toolbar                         from '@material-ui/core/Toolbar';
import IconButton                      from '@material-ui/core/IconButton';
import Typography                      from '@material-ui/core/Typography';
import CloseIcon                       from '@material-ui/icons/Close';
import Slide                           from '@material-ui/core/Slide';

import { CountryContext }              from '../../../contexts/filters/country';
import { MapContext }                  from '../../../contexts/map';
import { fullyFundedPerCountryAPI }    from '../../../apis/map';
import { decompress }                  from '../../../utils/compress';
import FullyFundedSection              from '../atoms/fullyFundedSection';
import WaitingLoader                   from '../atoms/waitingLoader';

const useStyles = makeStyles((theme) => ({
		appBar: {
				position  : 'sticky',
		},
		title: {
				marginLeft: theme.spacing(2),
				flex      : 1,
		},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
});

function ModalGrantInfo (props) {
		const classes            = useStyles();
		const [load, setLoader]  = useState (false);
		const [data, setData]    = useState ({});
		const { pin, selectPin } = useContext (MapContext);
		const { countryData }    = useContext (CountryContext);
		const open               = pin && pin.length ? true : false;

		const handleModalClose = () => {
				selectPin ('');
				setData ({});
		}
		useEffect (() => {
				async function fetchData () {
						try {
								if (!pin)
										return;
								setLoader (true);
								let result = await fullyFundedPerCountryAPI ({ 'isoCode' : pin });
								if (result.p)
										result.p = JSON.parse (decompress (result.p));
								if (result.f)
										result.f = JSON.parse (decompress (result.f));
								setData (result);
								setLoader (false);
						}
						catch (e) {
								setLoader (false);
						}
				}
				fetchData ();
		}, [pin]);

		const findCountryTitle = () => {
				for (let nation of countryData)
						if (pin === nation.iso_code)
								return nation.name;
				return 'Country Name';
		}

		return (
				<Dialog fullScreen open={open} onClose={handleModalClose} TransitionComponent={Transition}>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton edge="start" color="inherit" onClick={handleModalClose} aria-label="close">
								<CloseIcon />
							</IconButton>
							<Typography variant="h6" className={classes.title}>
								{findCountryTitle ()}
								<Typography gutterBottom>
									Total Scholarships Found : {data && data.p ? data.p.length : 'N/A'}
								</Typography>
							</Typography>
						</Toolbar>
					</AppBar>
					<div style={{padding: '20px'}}>
						{ load ? <WaitingLoader /> : <FullyFundedSection data={data} /> }
					</div>
				</Dialog>
		);
}

export default (memo (ModalGrantInfo));
