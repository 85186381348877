import React          from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card           from '@material-ui/core/Card';
import CardActions    from '@material-ui/core/CardActions';
import CardContent    from '@material-ui/core/CardContent';
import Button         from '@material-ui/core/Button';
import Typography     from '@material-ui/core/Typography';
import Grid           from '@material-ui/core/Grid';

import LeftData       from '../atoms/leftPart';
import RightQrCode    from '../atoms/qrCode';

const useStyles = makeStyles({
		root: {
				width         : '100%',
				margin        : '20px',
		},
		btn: {
				marginLeft        : 10,
				textTransform: 'none',
		},
});

export default function CourseDetail ({ data }) {
		const classes = useStyles();

		return (
				<Card className={classes.root} variant="outlined">
						<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={7}>
										<LeftData data={data} />
									</Grid>
									<Grid item xs={5} >
										<RightQrCode data={data} />
									</Grid>
								</Grid>

								<Typography component={'span'}>
										Description:
										<br />
										{
												data.description.split (';').map ((line, i) => (
														<div key={i}>
															{line}
														</div>
												))
										}
								</Typography>
						</CardContent>
						<CardActions>
								<Button className={classes.btn} variant="contained" size="medium" color="primary" href={data.link} target='_blank'>
										More Information
								</Button>
						</CardActions>
				</Card>
		);
}
