import React                 from 'react';
import { makeStyles }        from '@material-ui/core/styles';
import Typography            from '@material-ui/core/Typography';
import { semesterTrackUnit } from '../../../utils/exchange';

const useStyles = makeStyles ({
		title: {
				fontSize: 14,
		},
});

export default function LeftData ({ data }) {
		const classes   = useStyles ();
		const languages = {1: 'English', 3: 'French', 13: 'Italian' };

		const idToText = (arr, ids) => {
				let _lang = ids.map (id => arr[id]);
				return _lang.join (',');
		}

		return (
				<>
					<Typography variant="h5" component="h2">
							{data.title}
					</Typography>
					<Typography className={classes.title} color="textSecondary" gutterBottom>
							{data.dept}
					</Typography>
					<Typography variant="h6">
							{data.university}
					</Typography>
					<Typography>
							{semesterTrackUnit (data.semester)['n']} Semester
					</Typography>
					<Typography>
							{idToText (languages, data.language_id)}
					</Typography>
					<Typography>
							{data.credit} Credits
					</Typography>
					<br />
				</>
		);
};
