import React          from "react";
import Grid           from '@material-ui/core/Grid';
import Typography     from '@material-ui/core/Typography';

export default function UnderConstruction () {
        const title    = 'Our website is almost ready.';
        const subtitle = 'Will be back soon!';

        return (
				<div style={{height: '500px', margin: '15px'}}>
					<Grid container spacing={2}>
						<Grid item xs={false} md={3}></Grid>
						<Grid item xs={12} md={6} style={{paddingTop: '60px', textAlign: 'center'}}>
							<Typography variant="h3" gutterBottom>
								<b>{title}</b>
							</Typography>
							<Typography variant="h6" gutterBottom>
								{subtitle}
							</Typography>
						</Grid>
						<Grid item xs={false} md={3}></Grid>
					</Grid>
				</div>
		        );
}
