import React             from "react";
import { withStyles }    from "@material-ui/core/styles";
import Grid              from '@material-ui/core/Grid';

import NavigationBar     from '../home/molecules/navigationBar';
import SearchBar         from './molecules/searchBar';
import CenterPane        from './molecules/centerPane';
import Paginate          from './atoms/paginate';
import Footer            from '../home/atoms/footer';

const styles = theme => ({
		root: {
				flexGrow: 1,
		},
		container: {
				width   : '100%',
		},
});

class Exchange extends React.Component {
		/*constructor (props) {
				super (props);
				this.state = {
						value : 0,
				};
		}*/

		componentDidMount () {
			document.title = "Mobility/Exchange Courses | Overseas.one";
		}

		render (props) {
				const { classes } = this.props;
				return (
						<div className={classes.root}>
							<NavigationBar />
							<SearchBar />
							<Grid container spacing={2} className={classes.container}>
								<Grid item xs={false} md={2}>
								</Grid>
								<Grid container item xs={12} md={8} spacing={2}>
									<CenterPane />
								</Grid>
								<Grid item xs={false} md={2}>
								</Grid>
							</Grid>
							<Paginate />
							<Footer />
						</div>
				);
		}
}

export default withStyles (styles)(Exchange);
