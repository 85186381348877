import React             from "react";
import { withStyles }    from "@material-ui/core/styles";
import Grid              from '@material-ui/core/Grid';
import IconButton        from '@material-ui/core/IconButton';
import Typography        from '@material-ui/core/Typography';
import InstagramIcon     from '@material-ui/icons/Instagram';
import TwitterIcon       from '@material-ui/icons/Twitter';
import LocationOnIcon    from '@material-ui/icons/LocationOn';

import { contactAddress,
		contactEmail }   from '../../../config';

const styles = theme => ({
		container: {
				margin          : 0,
				width           : '100%',
				backgroundColor : '#1b1642',
				padding         : '60px 10px 60px 10px',
				color           : 'white',
		},
});

function AddressColumn () {
		return (
				<>
				<Grid item xs={12} md={2}>
					<LocationOnIcon fontSize="large" />
				</Grid>
				<Grid item xs={12} md={10}>
					<p>Address</p>
					{(Object.values (contactAddress)).join (', ')}
					<p>
						<a href={"mailto:"+contactEmail} style={{textDecoration: 'none', color:'white'}}>
							{contactEmail}
						</a>
					</p>
				</Grid>
				</>
		);
}

function SocialColumn () {
		return (
				<>
				<Grid item xs={12} md={12}>
					<Typography variant="h5">
						<b>Overseas.one</b>
					</Typography>
				</Grid>
				<Grid item xs={12} md={12}>
					<IconButton>
						<a href="https://instagram.com/overseasdotone" target="_blank" rel="noopener noreferrer">
							<InstagramIcon style={{color: 'white', margin: '5px'}} />
						</a>
						<a href="https://twitter.com/overseasdotone" target="_blank" rel="noopener noreferrer">
							<TwitterIcon style={{color: 'white', margin: '5px'}} />
						</a>
					</IconButton>
				</Grid>
				</>
		);
}

function Footer (props) {
		const { classes } = props;

		return (
				<Grid container spacing={2} className={classes.container}>
					<Grid item xs={false} md={1}>
					</Grid>
					<Grid container item xs={12} md={2}>
						<SocialColumn />
					</Grid>
					<Grid container item xs={false} md={2}>
					</Grid>
					<Grid container item xs={false} md={2}>
					</Grid>
					<Grid container item xs={false} md={2}>
					</Grid>
					<Grid container item xs={12} md={2} id="contact-us">
						<AddressColumn />
					</Grid>
					<Grid item xs={false} md={1}>
					</Grid>
			</Grid>
		);
}

export default withStyles (styles)(Footer);
