import React, { Component, createContext } from 'react';
import { countryAPI }                      from '../../apis/metadata';

export const CountryContext = createContext ();

class countryContextProvider extends Component {
		state = {
				countryData : [],
		}

		componentDidMount = async () => {
				let data  = await countryAPI (); //console.log (data);

				for (let i = 0; i < data.length; i++)
						data[i].isChecked = false;

				data.sort ((a, b) => {
					return this.compareStrings (a.name , b.name);
				})

				this.setState ({ countryData : data });
		}

		reset = () => {
				let data = [...this.state.countryData];

				if (!this.resetDiff ())
						return;

				for (let i = 0; i < data.length; i++)
						if (data[i].isChecked)
								data[i].isChecked = false;

				this.setState ({ countryData : data });
		}

		resetDiff = () => {
				let data = [...this.state.countryData];

				for (let i = 0; i < data.length; i++)
						if (data[i].isChecked)
								return true;
				return false;
		}

		compareStrings = (a, b) => {
			//case-insensitive comparison
			a = a.toLowerCase ();
			b = b.toLowerCase ();

			return (a < b ? -1 : (a > b ? 1 : 0));
		}

		toggleCheckbox = (_id) => {
				let data = [...this.state.countryData];

				for (let i = 0; i < data.length; i++) {
						if (data[i].id === _id) {
								data[i].isChecked = !data[i].isChecked;
								break;
						}
				}
				console.log ("setting isChecked id= ", _id);
				this.setState ({ countryData : data });
		}

		render () {
				return (
						<CountryContext.Provider value={{ ...this.state, toggleCountryChkbox: this.toggleCheckbox, resetCon: this.reset }}>
							{this.props.children}
						</CountryContext.Provider>
				);
		}
}

export default countryContextProvider;
