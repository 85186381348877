import React          from "react";
import Grid           from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";

import Header         from '../home/molecules/navigationBar';
import SearchBar      from './molecules/searchBar';
//import LeftPane       from './molecules/leftPane';
import CenterPane     from './molecules/centerPane';
import Paginate       from './atoms/paginate';
import Footer         from '../home/atoms/footer';

const styles = theme => ({
		root: {
				flexGrow: 1,
				//margin  : 15,
		},
		container: {
				width   : '100%',
		},
});

class Grants extends React.Component {
		/*constructor (props) {
				super (props);
				this.state = {};
		}*/
		/*componentDidMount () {
				function paramsToObject (entries) {
						const result = {}
						for(const [key, value] of entries) { // each 'entry' is a [key, value] tupple
								result[key] = value;
						}
						return result;
				}
				let params  = this.props.location.search.substring (1);
				params      = new URLSearchParams (params);
				let entries = params.entries ();
				const result = paramsToObject (entries);
		}*/

		componentDidMount () {
				document.title = "Scholarships and Courses | Overseas.one";
		}

		render (props) {
				const { classes } = this.props;

				return (
						<>
							<Header />
							<SearchBar />
							<Grid container spacing={2} className={classes.container}>
								<Grid item xs={false} md={2}>
								</Grid>
								<Grid container item xs={12} md={8} spacing={2}>
									<CenterPane />
								</Grid>
								<Grid item xs={false} md={2}>
								</Grid>
							</Grid>
							<Paginate />
							<Footer />
						</>
				);
		}
}

export default withStyles (styles)(Grants);
