export async function enrollNewsletterAPI (jsonBody) {
	try {
		let url = window.location.origin + '/user/newsletter/subscribe';
		const response = await fetch (url, {
			method : 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body   : JSON.stringify (jsonBody),
		});

		if (!response.ok)
			throw new Error (response.statusText);

		return response.json ();
	}
	catch (e) {
		throw Error (e);
	}
}
