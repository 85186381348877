import React, { createContext, Component } from 'react';

export const EmailContext = createContext ();

class EmailContextProvider extends Component {
		state = {
				name   : '',
				email  : '',
				phone  : '',
				content: '',
				load   : false,
		}

		toggleLoader = (val) => {
			this.setState ({ load : val });
		}

		onMailStateChange = (e) => {
				const { name, value } = e.target;
				this.setState ({ [name] : value });
		}

		resetMailState = () => {
				this.setState ({ 'name': '', 'email': '',
						'phone': '', 'content': '', 'load': false});
		}

		render () {
				return (
						<EmailContext.Provider value={{
							...this.state,
							onMailStateChange : this.onMailStateChange,
							resetMailState    : this.resetMailState,
							toggleLoader      : this.toggleLoader,
						}}>
							{this.props.children}
						</EmailContext.Provider>
				);
		}
}

export default EmailContextProvider;
