export const maxPageStore   = 10; //no of pages in context 10*10
export const maxPerPage     = 10; //no of entries in context 10*10
export const feeDollar      = 2;
export const feeRupee       = 150;
export const contactAddress = {
		'name'    : 'Sun Twilight',
		'street'  : 'Metro Street',
		'locality': 'Opposite Delta-1 Metro Station, Rep-2A, Sector 27, Greater Noida',
		'state'   : 'Uttar Pradesh',
		'pin'     : '201308',
		'country' : 'India'
};
export const contactEmail = 'hello@overseas.one';

export const springColor = '#f5e077';
export const summerColor = '#83cade';
export const autumnColor = '#973a06';

export const commentMax  = 280;//unit - chars

//export const springColor = '#66ffb2'; //delaram
//export const summerColor = '#ffe085';
//export const autumnColor = '#66d9ff';
