import React                      from "react";
import Routes                     from "./routes";

import CssBaseline                from '@material-ui/core/CssBaseline';
import { ThemeProvider }          from '@material-ui/core/styles';
import theme                      from './assets/styles/theme';

import ScholarshipContextProvider from './contexts/scholarship';
import ExchangeContextProvider    from './contexts/exchange';
import DisciplineContextProvider  from './contexts/filters/discipline';
import DegreeContextProvider      from './contexts/filters/degree';
import CountryContextProvider     from './contexts/filters/country';
import SearchStrContextProvider   from './contexts/filters/searchString';
import MapContextProvider         from './contexts/map';
import UserContextProvider        from './contexts/user';

export default function App() {
		return (
				<ThemeProvider theme={theme}>
				{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
					<ScholarshipContextProvider>
						<ExchangeContextProvider>
							<DisciplineContextProvider>
								<DegreeContextProvider>
									<CountryContextProvider>
										<SearchStrContextProvider>
											<MapContextProvider>
												<UserContextProvider>
													<CssBaseline />
													<Routes />
												</UserContextProvider>
											</MapContextProvider>
										</SearchStrContextProvider>
									</CountryContextProvider>
								</DegreeContextProvider>
							</DisciplineContextProvider>
						</ExchangeContextProvider>
					</ScholarshipContextProvider>
				</ThemeProvider>
		);
}
