import React            from "react";
import {
		BrowserRouter as Router,
		Switch, Route } from "react-router-dom";

import Home               from "./pages/home";
import Grants             from "./pages/grants";
import Dashboard          from "./pages/dashboard";
import LoginUp            from "./pages/loginUp";
import NotFound           from "./pages/notFound";
import AdmissionMap       from "./pages/admissionMap";
import FullScholarshipMap from "./pages/fullScholarshipMap/";
import About              from "./pages/about";
import Exchange           from "./pages/exchange";
import CourseReport       from "./pages/courseReport";
import Donate             from "./pages/donate";
import ScrollToTop        from "./components/scrollToTop";

import Blog             from "./pages/blog";

export default function App () {
		return (
				<Router>
					<ScrollToTop />
					<div>
						<Switch>
							<Route path="/app/scholarships"                  component={Grants} />
							<Route path="/app/admission-map"                 component={AdmissionMap} />
							<Route path="/app/fully-funded-scholarships-map" component={FullScholarshipMap} />
							<Route path="/app/dashboard"                     component={Dashboard} />
							<Route path="/app/mobility"                      component={Exchange} />
							<Route path="/app/course"                        component={CourseReport} />
							<Route path="/app/donate"                        component={Donate} />
							<Route path="/app/about"                         component={About} />
							<Route path="/app/login"                         component={LoginUp} />
							<Route path="/app/"                              component={Home} />
							<Route path="/"                                  component={Home} exact />
							<Route path="/blog"                              component={Blog} />
							<Route                                           component={NotFound} />
						</Switch>
					</div>
				</Router>
		);
};
