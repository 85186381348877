import React, { useState, useEffect } from 'react';
import { makeStyles }                 from '@material-ui/core/styles';
import TextField                      from '@material-ui/core/TextField';
import Avatar                         from '@material-ui/core/Avatar';
import Rating                         from '@material-ui/lab/Rating';
import Grid                           from '@material-ui/core/Grid';
import Moment                         from 'moment';

import { commentAPI }                 from '../../../apis/comment';
import { timeLeveler }                from '../../../utils/timeUnit';

const useStyles = makeStyles((theme) => ({
		root: {
				width         : '100%',
				margin        : '20px',
		},
		btn: {
				marginTop     : '10px',
				textTransform : 'none',
				float         : 'right',
		},
		purple: {
				color          : "white",
				backgroundColor: theme.palette.primary.main,
				width          : theme.spacing (4),
				height         : theme.spacing (4),
		}

}));

const initialState = {
		comments: {},
		page    : 1,
		loading : false,
		msg     : '',
};

export default function ListComment ({ id }) {
		const classes                                     = useStyles ();
		const [{ comments, page, loading, msg}, setState] = useState (initialState);

		/* componentDidMount */
		useEffect (() => {
				async function fetchData () {
						try {
								let resp = await commentAPI ({ 'cId': id, 'page': page });
								setState(prevState => ({ ...prevState, 'comments': resp }));
						}
						catch (err) {
								console.error (err);
						}
				}
				fetchData ();
		}, []);

		if (!comments || !comments.length)
				return (
						<Grid container spacing={2} style={{marginBottom: '5px', textAlign: 'center'}}>
							<Grid item xs={12} md={12}>
								<h3>No Comments Yet</h3>
								<i><h5>Be the first one to comment. Please sign-in/up to start.</h5></i>
							</Grid>
						</Grid>
				);
		return (
				<div className={classes.root}>
				{
					comments && comments.length && comments.map ((c, i) => (
						<div key={i}>
							<Grid container spacing={2} style={{marginBottom: '5px'}}>
								<Grid item xs={1} md={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
									<Avatar className={classes.purple}>
										<font size={2}>
											{c.first_name.charAt(0)}{c.last_name.charAt(0)}
										</font>
									</Avatar>
								</Grid>
								<Grid item xs={11} md={11} style={{display: 'flex', alignItems: 'center'}}>
									<i>{c.first_name} {c.last_name}</i>
									&nbsp;&nbsp;
									<font size={1}style={{marginTop: '4px'}}>
										<i>{timeLeveler (c.created_at)}</i>
									</font>
								</Grid>
							</Grid>
							<Grid container spacing={2} style={{marginBottom: '5px'}}>
								<Grid item xs={1} md={1}>
								</Grid>
								<Grid item xs={11} md={11} style={{display: 'flex', alignItems: 'center'}}>
									{c.remark}
								</Grid>
							</Grid>
							<Grid container spacing={2} style={{marginBottom: '5px'}}>
								<Grid item xs={1} md={1}>
								</Grid>
								<Grid item xs={11} md={11} style={{display: 'flex', alignItems: 'center'}}>
									<Rating
										size="small"
										precision={0.5}
										value={c.rate}
										readOnly
									/>
								</Grid>
							</Grid>
						</div>
					))
				}
				</div>
		);
}
