export async function exchangeAPI (jsonBody) {
		try {
				let url = window.location.origin + '/search/exchange';
				const response = await fetch (url, {
						method : 'POST',
						headers: {
								'Content-Type': 'application/json',
						},
						body   : JSON.stringify (jsonBody),
				});

				if (!response.ok)
						throw Error (response.statusText);

				return response.json ();
		}
		catch (e) {
				throw Error (e);
		}
}

export async function exchangeSubjectAPI (jsonBody) {
		try {
				let url = window.location.origin + '/search/subject';
				const response = await fetch (url, {
						method : 'POST',
						headers: {
								'Content-Type': 'application/json',
						},
						body   : JSON.stringify (jsonBody),
				});

				if (!response.ok)
						throw Error (response.statusText);

				return response.json ();
		}
		catch (e) {
				throw Error (e);
		}
}
