import React             from "react";
import { withStyles }    from "@material-ui/core/styles";
import { Link }          from 'react-router-dom';
import Grid              from '@material-ui/core/Grid';
import Button            from '@material-ui/core/Button';

import InputSearch       from './inputSearch';

const styles = theme => ({
	container: {
		padding       : '90px 10px 80px 10px',
		textAlign     : 'center', //when img comes -> remove
	},
	highlighter: {
		color         : 'indigo',
		fontWeight    : 'bold',
	},
	titleH1: {
		color         : '#2d3748',
		fontSize      : '3rem',
		fontWeight    : 'normal',
		margin        : '0px',
		lineHeight    : 'normal !important',
	},
	subTitleH2: {
		color         : '#718096',
		margin        : '0px',
		fontSize      : '1.25rem',
		fontWeight    : 'normal',
	},
	btnText: {
		textTransform : 'none', //capitals
		textDecoration: 'inherit',//underline
		color         : 'inherit',//font-color
	},
	btn: {
		marginRight   : '10px',
	},
	example: {
		padding       : '10px 0px 10px 0px',
		color         : '#718096',
	},
});

function Welcome (props) {
		const { classes } = props;
		const title       = ['Welcome to', 'overseas.one', 'Discover scholarships for your dream study abroad'];
		const subTitle    = 'Search among +100,000 of courses, including scholarships, at no-cost and 100% privacy protected';
		const exampleLine = 'Ex: Life Science, Health Science, Data Science or Computational Science';
		const buttons     = [' Scholarships ', ' World Map '];

		return (
				<Grid container spacing={2} className={classes.container}>
					<Grid item md={12} xs={12}>
						<h1 className={classes.titleH1}>
							{title[0]}
							<span className={classes.highlighter}> {title[1]} </span>
							<br />
							{title[2]}
						</h1>
						<br />
						<h2 className={classes.subTitleH2}>
							{subTitle}
						</h2>
					</Grid>
					<br />
					<Grid item xs={false} md={2}>
					</Grid>
					<Grid item xs={12} md={8}>
						<InputSearch />
					</Grid>
					<Grid item md={12} xs={12}>
						<div className={classes.example}>
							{exampleLine}
						</div>
						<br />
						<Button variant="contained" color="primary" className={classes.btn}>
							<Link to={'/app/scholarships'} className={classes.btnText}>{buttons[0]}</Link>
						</Button>
						<Button variant="outlined" color="primary" className={classes.btnText}>
							<Link to={'/app/admission-map'} className={classes.btnText}>{buttons[1]}</Link>
						</Button>
					</Grid>
				{false && <Grid container item md={6} xs={12} style={{backgroundColor: 'indigo'}} align="center" justify="center" direction='column'>
						<Grid><p>Img</p></Grid>
					</Grid>}
				</Grid>
		);
}

export default withStyles (styles)(Welcome);
