import React, { useContext } from "react";
import { Link }              from 'react-router-dom';
import { withStyles }        from "@material-ui/core/styles";
import { AppBar, Toolbar }   from '@material-ui/core';
import Grid                  from '@material-ui/core/Grid';
import Button                from '@material-ui/core/Button';

import LinkBtn               from "../atoms/linkButton";
import AccountBtn            from "../atoms/accountButton";
import Logo                  from '../../../assets/images/overseasEduLogo.png';
import { UserContext }       from '../../../contexts/user';

const styles = theme => ({
		logo: {
				width    : '50%',
				height   : '50%',
		},
		tabTitle: {
				textAlign     : 'center',
				fontSize      : 'medium',
				textDecoration: 'inherit',//underline
				color         : 'inherit',//font-color
				textTransform : 'none',   //capitals
		},
		brandName: {
				fontSize      : 'Large',
				fontWeight    : 'bold',
		},
});

function NavigationBar (props) {
		const { classes } = props;
		const pages       = [
			{ 'id': 0, 'route': '/app/about',        'name': 'About'},
			{ 'id': 1, 'route': '/app/scholarships', 'name': 'Scholarships'},
			{ 'id': 2, 'route': '/app/mobility',     'name': 'Mobility'},
			{ 'id': 3, 'route': '/blog',             'name': 'Blog'},
		];
		var { user }      = useContext (UserContext);

		return (
				<>
				<AppBar position="static" color="default" style={{backgroundColor: 'white'}}>
					<Toolbar>
						<Grid container spacing={1} alignItems="center" justify="center">
							<Grid item xs={12} md={1} style={{textAlign: 'center'}}>
								<img
									className={classes.logo}
									src={Logo}
									alt="Overseas.one#Logo"
								/>
							</Grid>
							<Grid item xs={12} md={2} className={classes.tabTitle}>
								<Button >
									<Link to={'/'} className={classes.tabTitle}>
										<span className={classes.brandName}>Overseas.one</span>
									</Link>
								</Button>
							</Grid>
							{ pages.map ((p) => (
									<Grid item xs={12} md={1} className={classes.tabTitle} key={p.id}>
										<Button >
											<Link to={p.route} className={classes.tabTitle}> {p.name} </Link>
										</Button>
									</Grid>
							))}
							<Grid item xs={12} md={1} className={classes.tabTitle}>
								<Button href="#contact-us" className={classes.tabTitle}>Contact</Button>
							</Grid>
							<Grid item xs={false} md={3} className={classes.tabTitle}>
							</Grid>
							<Grid item xs={12} md={1} className={classes.tabTitle}>
							{
									user && user.token ? <AccountBtn /> : <LinkBtn />
							}
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				</>
		);
}

export default withStyles (styles)(NavigationBar);
