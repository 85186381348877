import React               from 'react';
import { makeStyles }      from '@material-ui/core/styles';
import Pagination          from '@material-ui/lab/Pagination';

const useStyles = makeStyles ((theme) => ({
}));

export default function PaginationWrapper ({ _page, _count, _onChange }) {
		const classes = useStyles ();

		return (
				<Pagination
					page={_page}
					count={_count}
					color="primary"
					onChange={_onChange}
				/>
		);
}
