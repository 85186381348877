import { red }            from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

//A custom theme for this app
const theme = createMuiTheme({
		palette: {
				primary: {
						//main: '#556cd6',
						main: '#4b0082',
				},
				secondary: {
						main: '#19857b',
				},
				error: {
						main: red.A400,
				},
				background: {
						default: '#fff',
				},
		},
});

export default theme;
