import React, { Component, createContext } from 'react';

export const SearchStringContext = createContext ();

class searchStringContextProvider extends Component {
		state = {
				searchString : '',
				prevPath     : '',
		}

		componentDidMount = () => {
		}

		reset = () => {
				if (!this.resetDiff ())
						return;

				this.setState ({ searchString : '' });
		}

		resetDiff = () => {
				if (this.state.searchString.length)
						return true;
				return false;
		}

		pathReset = () => {
				this.setState ({ prevPath : '' });
		}

		setSearchString = (searchString, path) => {
				/* FIXME : react-router-dom <Link>
				 * component's state didn't work
				 */
				if (path && path.prevRoute && path.prevRoute.length)
						return this.setState ({ searchString, prevPath : path.prevRoute });

				this.setState ({ searchString });
		}

		render () {
				return (
						<SearchStringContext.Provider value={{ ...this.state,
							setSearchString: this.setSearchString,
							resetSea       : this.reset,
							pathReset      : this.pathReset }}
						>
							{this.props.children}
						</SearchStringContext.Provider>
				);
		}
}

export default searchStringContextProvider;
