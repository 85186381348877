import React, { useContext, useEffect }  from "react";
import { withStyles }         from "@material-ui/core/styles";
import Grid                   from '@material-ui/core/Grid';
import Typography             from '@material-ui/core/Typography';
import Card                   from '@material-ui/core/Card';
import CardActions            from '@material-ui/core/CardActions';
import CardContent            from '@material-ui/core/CardContent';
import Button                 from '@material-ui/core/Button';
import Link                   from '@material-ui/core/Link';

import UserForm               from '../atoms/userForm';
import Messenger              from '../../../components/messenger';
import { UserContext }        from '../../../contexts/user';
import { ceaseTokenAPI }      from '../../../apis/user';

const styles = theme => ({
		root: {
				flexGrow      :1,
		},
		logoTitle: {
				margin        :'30px',
				display       :'flex',
				justifyContent:'center',
				alignItems    :'center',
		},
		grid: {
				width         :'100%',
		},
		gridWidth: {
				width         :'100%',
				display       :'flex',
				justifyContent:'center',
				alignItems    :'center',
		},
});

const initialState = {
		email     : '',
		passwd    : '',
		visibility: false,
};
const initialStateMsg = {
		msg: '',
};

function SignIn (props) {
		const { classes, handleViewChange }             = props;
		const [{ msg }, setStateMsg]                    = React.useState (initialStateMsg);
		const [{ email, passwd, visibility }, setState] = React.useState (initialState);
		const { checkIn }                               = useContext (UserContext);

		/* componentDidMount */
		useEffect (() => {
				//FIXME: login page visible for 1 second
				async function autoCheckIn () {
						try {
								/* unable to intercept from context */
								if (!localStorage.getItem ('overseas.one'))
										return;

								let _user = JSON.parse (localStorage.getItem ('overseas.one'));
								let resp  = await ceaseTokenAPI (_user.token); //expiry-check
								if (resp)
										document.location.href = '/dashboard';
						}
						catch (err) {
								console.error (err);
						}
				}
				autoCheckIn ();
		}, []);

		const onChangeVisibility = () => {
				setState (prevState => ({ ...prevState, 'visibility': !visibility }));
		}

		const onKeyPress = (e) => {
				if (e.key === 'Enter')
						doLogin ();
		};

		const handleMesenger = () => {
				//console.log ('=============');
				setStateMsg ({ ...initialStateMsg });
		};

		const doLogin = async () => {
				try {
						if (!email.length || !passwd.length)
								return;

						let data       = {};
						data['email']  = email;
						data['passwd'] = passwd;

						let res = await checkIn (data);
						//console.log (res);

						if (res.err && res.message)
								setStateMsg (prevState => ({ ...prevState, 'msg' : res.message }));
						setState ({ ...initialState, 'email': email });

						if (res && res.token)
								document.location.href = '/dashboard';
				}
				catch (err) {
						setStateMsg (prevState => ({ ...prevState, 'msg' : err.message }));
				}
		}

		return (
			<div onKeyPress={(e) => onKeyPress (e)}>
			<Grid container spacing={2} className={classes.grid}>
				<Grid item xs={false} md={4}></Grid>
					<Grid container item xs={12} md={4} className={classes.gridWidth}>
						<Card style={{ width: '100%', padding: '20px', textAlign: 'center' }}>
							<CardContent>
								<Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
									Login to continue :
								</Typography>
								<br />
								<UserForm
									email={email}
									passwd={passwd}
									visibility={visibility}
									onChangeEmail={(val) => setState (prevState => ({ ...prevState, 'email': val }))}
									onChangePasswd={(val) => setState (prevState => ({ ...prevState, 'passwd': val }))}
									onChangeVisibility={onChangeVisibility} />
							</CardContent>
							<br /><br />
							<CardActions>
								<Button size="medium" variant="contained"
									color="primary" style={{textTransform: 'none', width: '100%'}}
									onClick={() => doLogin ()} >
									Login
								</Button>
							</CardActions>
						<br />
						<hr
							style={{
								color: 'grey', height: 2,
								backgroundColor: 'grey',
							}} />
						<br />
						<Link
							onClick={() => handleViewChange ('signin')}
							component="button" >
							Can't login?
						</Link>
						&nbsp; &bull; &nbsp;
						<Link
							onClick={() => handleViewChange ('register')}
							component="button" >
							Sign up for an account
						</Link>
					</Card>
				</Grid>
				<Messenger
					msg={msg}
					open={msg.length ? true: false}
					handleMesenger={handleMesenger}
				/>
			</Grid>
			</div>
		);
}

export default withStyles (styles)(SignIn);
