import React                           from 'react';
import { makeStyles }                  from '@material-ui/core/styles';
import Avatar                          from '@material-ui/core/Avatar';
import Grid                            from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
		purple: {
				color          : "white",
				backgroundColor: theme.palette.primary.main,
				width          : theme.spacing (4),
				height         : theme.spacing (4),
		}
}));

export default function AvatarName ({ firstName, lastName }) {
		const classes = useStyles ();

		if (firstName && lastName)
				return (
						<Grid container spacing={2} style={{marginBottom: '5px'}}>
							<Grid item xs={1} md={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
								<Avatar className={classes.purple}>
									<font size={2}>
										{firstName ? firstName.charAt(0).toUpperCase () : ''}
										{lastName ? lastName.charAt(0).toUpperCase () : ''}
									</font>
								</Avatar>
							</Grid>
							<Grid item xs={11} md={11} style={{display: 'flex', alignItems: 'center'}}>
								<i>
									{firstName ? firstName : ''}
									&nbsp;
									{lastName ? lastName : ''}
								</i>
							</Grid>
						</Grid>
				);
		return null;
}
