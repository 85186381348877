import React             from "react";
import { withStyles }    from "@material-ui/core/styles";
import Grid              from '@material-ui/core/Grid';

import ContactUsForm     from '../atoms/contactUsForm';
import RightPart         from '../atoms/storyContent';
import EmailContextProvider from '../../../contexts/email';


const styles = theme => ({
		container: {
				paddingTop : '40px',
		},
});

function Story (props) {
		const { classes } = props;

		return (
				<Grid container spacing={2} className={classes.container}>
					<EmailContextProvider>
						<ContactUsForm />
					</EmailContextProvider>
					<Grid item xs={false} md={2}></Grid>
					<RightPart />
				</Grid>
		);
}

export default withStyles (styles)(Story);
