import React          from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Input          from '@material-ui/core/Input';
import InputLabel     from '@material-ui/core/InputLabel';
import MenuItem       from '@material-ui/core/MenuItem';
import FormControl    from '@material-ui/core/FormControl';
import ListItemText   from '@material-ui/core/ListItemText';
import Select         from '@material-ui/core/Select';
import Checkbox       from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
		root : {
				flexGrow : 1,
		},
		formControl : {
				margin   : theme.spacing (1),
				minWidth : 200,
				maxWidth : 300,
		},
		noLabel : {
				marginTop: theme.spacing(3),
		},
}));

export default function MultiSelect ({ name, data, toggleCheckbox }) {
		const classes = useStyles ();

		if (!data)
				return <> <p>No Data Available</p> </>;

		const val = data.filter (item => {
				if (item.isChecked)
						return true;
				return false;
		}).map (idx => idx.name);

		return (
				<>
					<FormControl className={classes.formControl}>
						<InputLabel id="multiselect">{name}</InputLabel>
						<Select
							labelId="multiselect"
							id="mutiple-checkbox"
							multiple
							value={val}
							onChange={(e) => { toggleCheckbox (e.target.value.pop ()) }}
							input={<Input />}
							renderValue={(selected) => selected.join(', ')}
						>
						{data.map((item) => (
							<MenuItem key={item.id} value={item.id}>
								<Checkbox checked={item.isChecked} />
								<ListItemText primary={item.name} />
							</MenuItem>
						))}
						</Select>
					</FormControl>
				</>
		);
}
