import React ,{ useContext }   from 'react';
import { makeStyles }          from '@material-ui/core/styles';
import Grid                    from '@material-ui/core/Grid';

import { maxPerPage }          from '../../../config';
import { ExchangeContext }     from '../../../contexts/exchange';
import Pagination              from '../../../components/pagination';
import { SearchStringContext } from '../../../contexts/filters/searchString';
import { DisciplineContext }   from '../../../contexts/filters/discipline';
import { DegreeContext }       from '../../../contexts/filters/degree';
import { CountryContext }      from '../../../contexts/filters/country';
import { assembleSearch }      from '../../../utils/metaDataUtils';
import { exchangeAPI }         from '../../../apis/exchange';

const useStyles = makeStyles ((theme) => ({
		paper: {
				padding      : theme.spacing (1),
				color        : theme.palette.text.secondary,
				//fontSize     : 20,
				textAlign    : 'center',
				margin       : '10px',
		},
		align: {
				justifyContent: 'center',
				display       : 'flex',
		},
}));

export default function Paginate () {
		const classes                        = useStyles ();
		var { disciplineData }               = useContext (DisciplineContext);
		var { degreeData }                   = useContext (DegreeContext);
		var { countryData }                  = useContext (CountryContext);
		var { page, maxCount, onPageChange,
				exchangeData }               = useContext (ExchangeContext);
		var { searchString }                 = useContext (SearchStringContext);

		/* Page change
		 * Case 1: API call i.e. new-page
		 * Case 2: visit pre-loaded page
		 */
		const handlePageChange = async (event, value) => {
				if (!exchangeData)
						return;

				let pList   = Object.keys (exchangeData);

				if (pList.includes (String (value)))
						return onPageChange ({ 'page' : Number (value) });

				let params  = assembleSearch (searchString, disciplineData, degreeData, countryData);
				params.page = Number (value);
				let resp    = await exchangeAPI (params);
				resp.page   = Number (value);

				onPageChange (resp);
		}

		return (
				<div className={classes.paper}>
					<Grid container spacing={2}>
						<Grid item xs={false} md={2}></Grid>
						<Grid item xs={12} md={8} className={classes.align}>
							<Pagination
								_page={page}
								_count={Math.floor (maxCount/maxPerPage) + 1}
								_onChange={handlePageChange}
							/>
						</Grid>
						<Grid item xs={false} md={2}></Grid>
					</Grid>
				</div>
		);
}
