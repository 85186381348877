import React                  from "react";
import { withStyles }         from "@material-ui/core/styles";
import Grid                   from '@material-ui/core/Grid';
import Typography             from '@material-ui/core/Typography';

import NavigationBar          from '../home/molecules/navigationBar';
import Logo                   from '../../assets/images/overseasEduLogo.png';
import SignIn                 from './molecules/signIn';
import Register               from './molecules/register';
import Footer                 from '../home/atoms/footer';
import Link                   from '@material-ui/core/Link';

const styles = theme => ({
		root: {
				flexGrow      : 1,
		},
		logo: {
				width         : '70px',
				height        : '50px',
		},
		logoTitle: {
				margin        : '30px',
				display       :'flex',
				justifyContent:'center',
				alignItems    :'center',
		},
		grid: {
				width         : '100%',
		},
		gridWidth: {
				width         : '100%',
				display       :'flex',
				justifyContent:'center',
				alignItems    :'center',
		},
});

class LoginUp extends React.Component {
		constructor (props) {
				super (props);
				this.state = {
						action: 'signin', // 'register' 'forget'
				};
		}

		handleViewChange = (action) => {
				//console.log (action);

				/* possibility to show 3 types of views
				 * on app/login
				 */
				switch (action) {
						case 'signin'  : this.setState ({ action });
								break;
						case 'register': this.setState ({ action });
								break;
						case 'forget'  : this.setState ({ action });
								break;
						default        : this.setState ({ action: 'signin' }); // handle exception
				}
		}

		handleClickShowPassword () {
		}

		handleMouseDownPassword () {
		}

		renderView () {
			if (this.state.action === 'signin')
				return (
					<SignIn
						handleViewChange={this.handleViewChange}
					/>
				);

			if (this.state.action === 'register')
				return (
					<Register
						handleViewChange={this.handleViewChange}
					/>
				);
		}

		render (props) {
				const { classes } = this.props;

				return (
						<div className={classes.root}>
							<NavigationBar />
							<Grid container spacing={2} className={classes.grid}>
								<Grid item xs={false} md={4}>
								</Grid>
								<Grid container item xs={12} md={4} className={classes.gridWidth}>
									<div className={classes.logoTitle}>
										<img
											className={classes.logo}
											src={Logo}
											alt="Overseas.one#Logo"
										/>
										<Typography variant="h5" component="div" color="primary">
											<b>Overseas.one</b>
										</Typography>
									</div>
								</Grid>
							</Grid>
							{this.renderView ()}
							<Grid container spacing={2} className={classes.grid}>
								<Grid item xs={false} md={4}>
								</Grid>
								<Grid container item xs={12} md={4} className={classes.gridWidth}>
									<div style={{ marginTop: '10px', marginBottom: '50px' }}>
										<Link
											href="/public/privacypolicy"
										>
											Privacy Policy
										</Link>
										&nbsp; &bull; &nbsp;  User Notice
									</div>
								</Grid>
							</Grid>
							<Footer />
						</div>
				);
		}
}

export default withStyles (styles)(LoginUp);
