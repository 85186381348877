import React             from "react";
import { withStyles }    from "@material-ui/core/styles";

import NavigationBar     from '../home/molecules/navigationBar';
import UnderConstruction from '../admissionMap/atoms/underConstruction';
import Footer            from '../home/atoms/footer';

const styles = theme => ({
		root: {
				flexGrow: 1,
		},
		container: {
				padding : '60px 10px 60px 10px',
		},
});

class Dashboard extends React.Component {
		constructor (props) {
				super (props);
				this.state = {
						value : 0,
				};
		}

		render (props) {
				const { classes } = this.props;
				return (
						<div className={classes.root}>
							<NavigationBar />
							<UnderConstruction />
							<Footer />
						</div>
				);
		}
}

export default withStyles (styles)(Dashboard);
