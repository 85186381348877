import React, { useContext } from 'react';
import { withStyles }   from '@material-ui/core/styles';
import Dialog           from '@material-ui/core/Dialog';
import MuiDialogTitle   from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton       from '@material-ui/core/IconButton';
import CloseIcon        from '@material-ui/icons/Close';
import Typography       from '@material-ui/core/Typography';
import Grid             from '@material-ui/core/Grid';

import { CountryContext } from '../../../contexts/filters/country';

const styles = (theme) => ({
		root: {
				margin  : 0,
				padding : theme.spacing (2),
		},
		closeButton: {
				position: 'absolute',
				right   : theme.spacing (1),
				top     : theme.spacing (1),
				color   : theme.palette.grey[500],
		},
});

const DialogTitle = withStyles (styles) ( (props) => {
		const { children, classes, onClose, ...other } = props;
		return (
				<MuiDialogTitle disableTypography className={classes.root} {...other}>
				<Typography variant="h6">{children}</Typography>
				{onClose ? (
						<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
						<CloseIcon />
						</IconButton>
				) : null}
				</MuiDialogTitle>
		);
});

const DialogContent = withStyles((theme) => ({
		root: {
				padding : theme.spacing(2),
		},
}))(MuiDialogContent);

function GeneralRequirement ({ data }) {
		var { countryData } = useContext (CountryContext);

		if (!data)
				return null;

		let nation = countryData.find (ele => ele.id === data.country_id);

		return (
				<Grid container spacing={3}>
					<Grid item xs={6}> Country Name :</Grid><Grid item xs={6}> {nation && nation.name}</Grid>
					<Grid item xs={6}> Required CV :</Grid><Grid item xs={6}> {data.required_cv ? data.required_cv : 'No'}</Grid>
					<Grid item xs={6}> Required GRE :</Grid><Grid item xs={6}> {data.required_gmat_min ? data.required_gmat_min : 'No'}</Grid>
					<Grid item xs={6}> Required IELTS Score :</Grid><Grid item xs={6}> {data.required_ielts_min}</Grid>
					<Grid item xs={6}> Any entrance exam :</Grid><Grid item xs={6}> {data.required_internal_exam ? 'Yes' : 'No'}</Grid>
					<Grid item xs={6}> Recommendation Letters :</Grid><Grid item xs={6}> {data.required_letters ? data.required_letters : 'No'}</Grid>
					<Grid item xs={6}> Required SAT :</Grid><Grid item xs={6}> {data.required_sat ? 'Yes' : 'No'}</Grid>
					<Grid item xs={6}> Required TOEFL Score :</Grid><Grid item xs={6}> {data.required_toefl_min}</Grid>
					<Grid item xs={6}> Other Requirements :</Grid><Grid item xs={6}> {data.requirements}</Grid>
					<Grid item xs={12}> For more info click <a target="_blank" rel="noreferrer" href={data.requirements_link}> here.</a> </Grid>
				</Grid>
		);
}

function Grant ({ data }) {
		if (!data || !data.scholarships)
				return null ;
		return (
				<>
				<Typography variant="h6" gutterBottom>
				<b>Scholarships :</b>
				</Typography>
				{
						data.scholarships.map ((item) => (
								<span key={item.id}>
								<hr />
								<p> Title               : {item.title} </p>
								<p> Amount              : ${item.coverage_amount} </p>
								<p> Living Expenses     : {item.living ? 'Yes' : 'No'} </p>
								<p> Description         : {item.description} </p>
								<p> Requirements        : {item.requirements} </p>
								<p> Available positions : {item.number_of_spots > 0 ? item.number_of_spots : ' N/A'} </p>
								<p> For more info click <a target="_blank" rel="noreferrer" href={item.link}> here.</a> </p>
								</span>
						))
				}
				</>
		);
}

export default function ModalLearnMore ({ open, handleModalClose }) {
		const _open = Object.keys (open).length ? true : false;
		const data  = open;

		return (
				<div>
					<Dialog
						onClose={handleModalClose}
						aria-labelledby="modalLearnMore"
						open={_open} maxWidth={'md'}
						disableBackdropClick disableEscapeKeyDown >
						<DialogTitle id="modalLearnMore" onClose={handleModalClose}>
							{data.title}
							<Typography gutterBottom>
								{data.university_ids && data.university_ids.length ?
										data.university_ids.map ((u) => { return u.name; }).join ("\n") : "University Name Un-available"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers>
							<GeneralRequirement data={data} />
							<Grant data={data} />
						</DialogContent>
					</Dialog>
				</div>
		);
}
