export const NOTFOUND     = 'No data Found';
export const DISCLAIMER   = 'Note- The data shown above is cumulative. Please check with the respective institutions.';

//Email
export const MAIL         = 'Thanks for contacting us. Our team will get back to you in next 24-48 hours.';
export const NAME_ERR     = 'Please fill-in your full name.';
export const EMAIL_ERR    = 'Please fill-in your email-id.';
export const CONTENT_ERR  = 'Please fill-in your comments/thoughts.';

//Register
export const FNAME        = 'Please fill in your first name.';
export const LNAME        = 'Please fill in your last name.';
export const MAILID       = 'Please fill in your email-id.';
export const MAIL_INVALID = 'Please write a valid email.';
export const PASSWD       = 'Please fill in password.';
export const PASSWD_REGEX = 'Please use at least a special symbol, digit, upper and lower case letter.';
export const RETYPE_PASS  = 'Please re-enter the password correctly.';
export const PASS_MISMATCH= 'Passwords do not match.';
export const PHONE        = 'Please fill in phone number of 12 digits (including country code).';
export const PHONE_INVALID= 'Phone number must be numeric.';

//Comment
export const REMARK_ADD   = 'Comment saved. Refresh to view.';
export const REMARK_LEN   = 'Please write your comments/opinions.';
