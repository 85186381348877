import React                 from "react";
import { withStyles }        from "@material-ui/core/styles";
import ReactTooltip          from "react-tooltip";

import NavigationBar         from '../home/molecules/navigationBar';
import SetMapLabel           from './atoms/setMapLabel';
import WorldMap              from './molecules/worldMap';
import CountryListing        from './atoms/countryListing';
import Footer                from '../home/atoms/footer';
import ModalGrantInfo        from './molecules/modalGrantInfo';

const styles = theme => ({
	root: {
		flexGrow: 1,
	},
	container: {
		padding : '60px 10px 60px 10px',
	},
});

class AdmissionMap extends React.Component {
	constructor (props) {
		super (props);
		this.state = {
			tooltip : '',
		};
	}

	componentDidMount () {
		document.title = "Fully Funded Scholarships Map | Overseas.one";
		/* Functional Component
		 * SetLabelMap : sets the MapContext "mapLabel":'grant'
		 * since class can't use context hooks
		 */
	}

	setTooltipContent = (tooltip) => {
		this.setState ({ tooltip });
	}

	render (props) {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<NavigationBar />
				<SetMapLabel />
				<WorldMap setTooltipContent={this.setTooltipContent} />
				{
					this.state.tooltip.length ? <ReactTooltip> {this.state.tooltip} </ReactTooltip> : null
				}
				<CountryListing />
				<Footer />
				<ModalGrantInfo />
			</div>
		);
	}
}

export default withStyles (styles)(AdmissionMap);
