export async function registerAPI (jsonBody) {
		try {
				//let url = window.location.origin + '/search/exchange';
				let url = window.location.origin + '/auth/user/register';
				const response = await fetch (url, {
						method : 'POST',
						headers: {
								'Content-Type': 'application/json',
						},
						body   : JSON.stringify (jsonBody),
				});

				if (!response.ok)
						throw Error (response.statusText);

				return response.json ();
		}
		catch (e) {
				throw Error (e);
		}
}

export async function loginAPI (jsonBody) {
		try {
				//let url = window.location.origin + '/auth/user/login';
				let url = window.location.origin + '/auth/user/login';
				const response = await fetch (url, {
						method : 'POST',
						headers: {
								'Content-Type': 'application/json',
						},
						body   : JSON.stringify (jsonBody),
				});

				if (!response.ok)
						throw Error (response.statusText);

				return response.json ();
		}
		catch (e) {
				throw Error (e);
		}
}

export async function saveCommentAPI (jsonBody, token) {
		try {
				let url = window.location.origin + '/user/comment/create';
				const response = await fetch (url, {
						method : 'POST',
						headers: {
								'Content-Type' : 'application/json',
								'Authorization': token,
						},
						body   : JSON.stringify (jsonBody),
				});

				if (!response.ok)
						throw Error (response.statusText);

				return response.json ();
		}
		catch (e) {
				throw Error (e);
		}
}

export async function ceaseTokenAPI (token) {
		try {
				//let url = window.location.origin + '/protected';
				let url = window.location.origin + '/auth/protected';
				const response = await fetch (url, {
						method : 'GET',
						headers: {
								'Content-Type' : 'application/json',
								'Authorization': token,
						},
				});

				if (!response.ok)
						throw Error (response.statusText);

				return response.json ();
		}
		catch (e) {
				throw Error (e);
		}
}
