import { REMARK_LEN } from '../message';

export function validate (data) {
		//No aggressive checks

		if (!data.remark.length)
				throw new Error (REMARK_LEN);

		return data;
}
