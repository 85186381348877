import React ,{ useContext } from 'react';
import { makeStyles }        from '@material-ui/core/styles';
import Paper                 from '@material-ui/core/Paper';
import Grid                  from '@material-ui/core/Grid';
import TextField             from '@material-ui/core/TextField';

import SearchButton          from '../atoms/searchButton';
import MultiSelect           from '../atoms/multiSelect';
import { DisciplineContext } from '../../../contexts/filters/discipline';
import { DegreeContext }     from '../../../contexts/filters/degree';
import { CountryContext }    from '../../../contexts/filters/country';
import { SearchStringContext } from '../../../contexts/filters/searchString';
//import { retrieveIds, assembleSearch } from '../../../utils/metaDataUtils';

const useStyles = makeStyles ((theme) => ({
		root: {
				flexGrow     : 1,
				margin       : 15,
		},
		paper: {
				padding      : theme.spacing (1),
				color        : theme.palette.text.secondary,
				fontSize     : 20,
				textAlign    : 'center',
				margin       : '10px',
		},
		margin: {
				padding      : 24,
		},
		searchBtn: {
				textTransform: 'none',
				height       : "100%" ,
				width        : "100%",
		},
		textField: {
				marginTop    : '8px',
		},
}));

export default function SearchBar () {
		const classes                            = useStyles ();
		var { disciplineData, toggleCheckbox }   = useContext (DisciplineContext);
		var { degreeData, toggleDegreeChkbox }   = useContext (DegreeContext);
		var { countryData, toggleCountryChkbox } = useContext (CountryContext);
		var { searchString, setSearchString }    = useContext (SearchStringContext);
		//<form className={classes.textField} noValidate autoComplete="off">

		return (
				<Paper className={classes.paper}>
					<Grid container spacing={2} >
						<Grid container item xs={12} md={10}>

							<Grid item xs={12} md={3}>
								<TextField
									className={classes.textField}
									value={searchString}
									label="Search Course"
									onChange={(e) => setSearchString (e.target.value)} />
							</Grid>
							<Grid item xs={12} md={3}>
								<MultiSelect name={'Discipline'} data={disciplineData} toggleCheckbox={toggleCheckbox} />
							</Grid>
							<Grid item xs={12} md={3}>
								<MultiSelect name={'Degree'}  data={degreeData}  toggleCheckbox={toggleDegreeChkbox} />
							</Grid>
							<Grid item xs={12} md={3}>
								<MultiSelect name={'Country'} data={countryData} toggleCheckbox={toggleCountryChkbox} />
							</Grid>

						</Grid>
						<Grid item xs={12} md={2}>
							<SearchButton />
						</Grid>
					</Grid>
				</Paper>
		);
}
