import React, { useContext }           from 'react';
import { withStyles }                  from '@material-ui/core/styles';
import Grid                            from '@material-ui/core/Grid';

import { DegreeContext }               from '../../../contexts/filters/degree';
import { DisciplineContext }           from '../../../contexts/filters/discipline';

const styles = (theme) => ({
		root: {
				flexGrow: 1,
		},
});

function GrantListing ({ data }) {
		const { degreeData }     = useContext (DegreeContext);
		const { disciplineData } = useContext (DisciplineContext);

		const convertExp = (exp) => {
				if (exp < 12)
						return exp+' Month';
				return ((Math.ceil (exp/12))+' Year');
		};

		const stipend = (json) => {
				let expnd = [];
				if (!json)
						return 'N/A';
				if (json.firstCycle)
						expnd.push ('Bachelor\'s : '+json.firstCycle);
				if (json.secondCycle)
						expnd.push ('Master\'s : '+json.secondCycle);
				if (json.longCycle)
						expnd.push ('5 Yr courses : '+json.longCycle);
				if (json.fullCycle)
						expnd.push ('PhD\'s : '+json.fullCycle);
				return expnd.join (', ');
		};

		const degreeTypes = (d) => {
				let award = [];
				for (let ele of degreeData)
						if (d.includes (ele.id))
								award.push (ele.name);
				return award.sort ().join (', ');
		};

		const fieldTypes = (fld) => {
				let path = [];
				if (fld.includes ('ALL'))
						return "All";
				for (let ele of disciplineData)
						if (fld.includes (ele.id))
								path.push (ele.name);
				return path.sort ().join (', ');
		};

		return (
			<>{
				data && data.map ((item) => (
					<Grid container spacing={3} key={item.id}>
						<Grid item xs={12}><hr /></Grid>
						<Grid item xs={4}>Grant Name :</Grid><Grid item xs={8}>{item.title}</Grid>
						<Grid item xs={4}>Degree Supported :</Grid>
							<Grid item xs={8}>{item.degree_ids && item.degree_ids.length ? degreeTypes (item.degree_ids) : 'N/A'}</Grid>
						<Grid item xs={4}>Field Supported :</Grid>
							<Grid item xs={8}>{item.fields && item.fields.length ? fieldTypes (item.fields) : 'N/A'}</Grid>
						<Grid item xs={4}>Living Expenses :</Grid><Grid item xs={8}>{item.living_expenses ? 'Yes' : 'N/A'}</Grid>
						<Grid item xs={4}>Offer Letter Required :</Grid><Grid item xs={8}>{item.offer_letter ? 'Yes' : 'N/A'}</Grid>
						<Grid item xs={4}>Reference Letters :</Grid>
							<Grid item xs={8}>{item.reference_letter ? item.reference_letter : 'N/A'}</Grid>
						<Grid item xs={4}>Work Experience :</Grid>
							<Grid item xs={8}>{item.experience ? convertExp (item.experience) : 'N/A'}</Grid>
						<Grid item xs={4}>Eligibility :</Grid><Grid item xs={8}>{item.eligibility ? item.eligibility : 'N/A'}</Grid>
						<Grid item xs={4}>Requirement :</Grid><Grid item xs={8}>{item.requirements ? item.requirements : 'N/A'}</Grid>
						<Grid item xs={4}>Stipend :</Grid><Grid item xs={8}>{stipend (item.stipend)}</Grid>
						<Grid item xs={12}>For more info click <a target="_blank" rel="noreferrer" href={item.link}> here.</a> </Grid>
					</Grid>
				))
			}</>
		);
}

export default withStyles (styles)(GrantListing);
