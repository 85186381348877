import React, { createContext, Component } from 'react';
import { exchangeAPI }                     from '../apis/exchange';
import { maxPageStore }                    from '../config';

export const ExchangeContext = createContext ();

class ExchangeContextProvider extends Component {
		state = {
				exchangeData : {},
				maxCount     : 0,
				page         : 1, //user's current page i.e. zero
				perPage      : 10,
		}

		componentDidMount = async () => {
				let res = await exchangeAPI ();
				this.setState ({
						exchangeData : { 1 : res.data },
						maxCount     : res.count
				});
		}

		//search-btn
		updateState = (d) => {
				if (!d.data.length || !d.count)
						return this.setState ({
								exchangeData : {},
								page         : 1,
								maxCount     : d.count
						});

				this.setState ({
						exchangeData : { 1 : d.data},
						page         : 1,
						maxCount     : d.count
				});
		}

		updatePage = (d) => {
				if (!d.data)
						return this.setState ({ page : d.page });

				let _d = {};
				if (Object.keys (this.state.exchangeData).length < maxPageStore) //storage
						_d = { ...this.state.exchangeData };

				_d[d.page] = d.data;

				this.setState ({
						exchangeData : _d,
						page         : d.page
				});
		}

		render () {
				return (
						<ExchangeContext.Provider value={{ ...this.state, updateExchange: this.updateState, onPageChange: this.updatePage }}>
							{this.props.children}
						</ExchangeContext.Provider>
				);
		}
}

export default ExchangeContextProvider;
