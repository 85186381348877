import React, { Component, createContext } from 'react';
import { degreeAPI }                       from '../../apis/metadata';

export const DegreeContext = createContext ();

class degreeContextProvider extends Component {
		state = {
				degreeData : [],
		}

		componentDidMount = async () => {
				let data  = await degreeAPI (); //console.log ("degree API call ------------------- ", data);

				for (let i = 0; i < data.length; i++)
						data[i].isChecked = false;

				this.setState ({ degreeData : data });
		}

		reset = () => {
				let data = [...this.state.degreeData];

				if (!this.resetDiff ())
						return;

				for (let i = 0; i < data.length; i++)
						if (data[i].isChecked)
								data[i].isChecked = false;

				this.setState ({ degreeData : data });
		}

		resetDiff = () => {
				let data = [...this.state.degreeData];

				for (let i = 0; i < data.length; i++)
						if (data[i].isChecked)
								return true;
				return false;
		}

		toggleCheckbox = (_id) => {
				let data = [...this.state.degreeData];

				for (let i=0;i<data.length;i++) {
						if (data[i].id === _id) {
								data[i].isChecked = !data[i].isChecked;
								break;
						}
				}
				//console.log ("setting isChecked id= ", _id);
				this.setState ({ degreeData : data });
		}

		render () {
				return (
						<DegreeContext.Provider value={{ ...this.state, toggleDegreeChkbox: this.toggleCheckbox, resetDeg: this.reset }}>
							{this.props.children}
						</DegreeContext.Provider>
				);
		}
}

export default degreeContextProvider;
